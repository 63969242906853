import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedProductsComponent } from '@lib/lib/saved-products/saved-products.component';
import { SavedProductCardModule } from '@lib/lib/saved-product-card/saved-product-card.module';
import { MaxPizzaModalModule } from '@lib/lib/max-pizza-modal/max-pizza-modal.module';

@NgModule({
  declarations: [SavedProductsComponent],
  imports: [CommonModule, SavedProductCardModule, MaxPizzaModalModule],
  exports: [SavedProductsComponent]
})
export class SavedProductsModule {}
