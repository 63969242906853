import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { from, Observable, TimeInterval } from 'rxjs';
import { BasketStateModel, BasketState } from '@app/store/state/basket.state';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { first, map, switchMap } from 'rxjs/operators';
import {
  ApplyCoupon,
  ApplyGiftCard,
  CheckBalance,
  EmptyValidation,
  GetBillingSchemes,
  RemoveGiftCard,
  SetBillingInfo,
  SetTipAmount,
  SubmitBasket,
  ValidateBasket,
  SaveExtraCreditCard,
  SetOrderPlaceholder,
  RemoveOrderPlaceholder
} from '@app/store/actions/basket.action';
import { ToastrService } from 'ngx-toastr';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import { SetBanner } from '@app/store/actions/app.action';
import { UserStateModel } from '@app/store/state/user.state';
import { CapacityService } from '@app/providers/capacity.service';
import { CapacityErrorComponent } from 'src/assets/chepri-modules/src/lib/capacity-error/capacity-error.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { SplitPaymentService } from '@app/providers/splitpayment.service';
import { GiftCardService } from '@app/providers/giftCard.service';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { TimeoutModalComponent } from 'src/assets/chepri-modules/src/lib//timeout-modal/timeout-modal.component';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { Basket } from '../../../assets/chepri-modules/src/models/olo.basket';
import { environment } from '@env/environment';
import { GlobalStateModel } from '@app/store/state.model';
import { Moment } from 'moment';
import { DateTime } from '@lib/models/DateTime';
import { OloPaySDK, DigitalWalletReadyEvent, DigitalWalletStatus } from '@olo/pay-capacitor';
import { CardOptions, CreditCardElements, DigitalWallets } from '@olo/pay';
import { aw } from '@directus/sdk/dist/index-7ec1f729';
import { AnalyticsService } from '@app/providers/analytics.service';
import { Restaurant } from '@lib/models/olo.restaurant';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select() basket$: Observable<BasketStateModel>;
  @Select() user$: Observable<UserStateModel>;
  @Select((state: GlobalStateModel) => state.restaurant.restaurant) restaurant$: Observable<Restaurant>;

  @Input() saveCardText = 'Save Card';
  @Input() options: { value: string; text: string };
  @Input() isPayment: boolean;
  @Input() tipPercent = [{ percent: '15%' }, { percent: '20%' }, { percent: '25%' }, { percent: 'No Tip' }];

  @Output() creditCardSubmit = new EventEmitter<UntypedFormGroup>();
  @Output() giftCardSubmit = new EventEmitter<UntypedFormGroup>();

  couponPromoForm: UntypedFormGroup;
  paymentMethod: UntypedFormControl = new UntypedFormControl('creditcard');
  billingAccountControl: UntypedFormControl = new UntypedFormControl('default');
  paymentTypeForm: UntypedFormGroup;
  addCreditCardForm: UntypedFormGroup;
  addAdditionCreditCardForm: UntypedFormGroup;
  addGiftCardForm: UntypedFormGroup;
  addSecondGiftCardForm: UntypedFormGroup;
  addThirdGiftCardform: UntypedFormGroup;
  amExMask = '0000-000000-000009';
  regularMask = '0000-0000-0000-0000';
  showBalance = false;
  addAdditionPayment = false;
  addNextGiftCard = false;
  additionText = 'add credit card payment';
  giftText = 'add additional gift card';
  canaddExtraCard = true;
  currentCardMask = this.regularMask;
  currentCVVMask = '000';
  currentZIPMask = '00000';
  balance = 0;
  errorMessage = '';
  noNeedAdditionPay = true;
  basket = this.store.selectSnapshot(BasketState);
  basketSubmit: BillingInfo = new BillingInfo();
  giftCard = false;
  lowerTip: number;
  middleTip: number;
  upperTip: number;
  noTip: number;
  tipText: string;
  tipForm: UntypedFormGroup;
  selected = 4;
  numericRegex = /^\$?[0-9]+\.?[0-9]?[0-9]?$/;
  giftTotal = 0;
  showCashMethod = true;
  inputOrder = {
    creditcard: 0,
    giftcard: 1,
    payinstore: 3
  };
  isLoading = false;
  showing = false;
  intervalTimer: any;
  timeLeft: string;
  timeLimitLoading = true;

  supportsApplePay = Capacitor.getPlatform() === 'ios';
  supportsGooglePay = Capacitor.getPlatform() === 'android';
  digitalWalletsReady = false;

  digitalWalletReadyEventListener: PluginListenerHandle;

  shouldDivideTip = false;

  saveCard = new FormControl<boolean>(false);
  zipCode = new FormControl<string>('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]);
  country = new FormControl<string>('US', [Validators.required]);
  cardElements = new CreditCardElements(!environment.production ? 'development' : 'production');
  digitalWallets = new DigitalWallets(!environment.production ? 'development' : 'production');

  cardOptions: CardOptions = {
    elementsOptions: {
      appearance: {
        theme: 'flat',
        variables: {
          colorTextPlaceholder: '#000000',
          colorPrimaryText: '#000000'
        }
      },
      fonts: [
        {
          src: 'url("https://cms.punchpizza.chepri.com/assets/06a58dce-17f7-4736-8176-75a6bc9bffd4")',
          family: 'brandon',
          style: 'normal',
          weight: '400',
          display: 'swap'
        },
        {
          src: 'url("https://cms.punchpizza.chepri.com/assets/fae7bec2-1c8e-4b25-af1e-33126a049998")',
          family: 'brandon',
          style: 'normal',
          weight: '400',
          display: 'swap'
        },
        {
          src: 'url("https://cms.punchpizza.chepri.com/assets/f7295e15-9206-4b0f-9ed1-3ee6ac60b9fc")',
          family: 'brandon',
          style: 'normal',
          weight: '700',
          display: 'swap'
        },
        {
          src: 'url("https://cms.punchpizza.chepri.com/assets/a8b964c4-6e47-4f28-a310-8218050a3cbf")',
          family: 'brandon',
          style: 'normal',
          weight: '700',
          display: 'swap'
        }
      ]
    },
    cardElementOptions: {
      style: {
        base: {
          fontFamily: 'brandon, sans-serif',
          fontSize: '16px',
          color: '#000000'
        }
      }
    },
    placeholders: {
      cvv: 'CVV',
      expiry: 'Exp. Date',
      number: 'Card Number'
    }
  };

  stripeInitializationFailures = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
    private capacityService: CapacityService,
    private modalService: NgbModal,
    private splitPay: SplitPaymentService,
    private gc: GiftCardService,
    private analytics: AnalyticsService
  ) {}

  async ngOnInit() {
    if (Capacitor.getPlatform() !== 'web') {
      this.digitalWalletReadyEventListener = await OloPaySDK.addListener(
        DigitalWalletReadyEvent,
        (info: DigitalWalletStatus) => {
          this.digitalWalletsReady = info.isReady;
          this.digitalWalletReadyEventListener?.remove();
        }
      );
      OloPaySDK.initialize({
        freshInstall: !environment.production,
        productionEnvironment: environment.production,
        applePayMerchantId: 'merchant.com.chepri.punchpizza',
        applePayCompanyLabel: 'Punch Neapolitan Pizza'
      }).then(() => {
        if (Capacitor.getPlatform() === 'android') {
          OloPaySDK.initializeGooglePay({
            merchantName: 'Punch Neapolitan Pizza',
            countryCode: 'US',
            googlePayProductionEnvironment: environment.production
          });
        }
      });
    }
    this.store.dispatch(new ValidateBasket());
    this.store.dispatch(new SetBanner('ORDER DETAILS', true, true, ''));
    this.store.dispatch(new GetBillingSchemes());
    // hide cash method in online ordering checkout
    // shown in kiosk mode
    if (window.location.href.includes('order') || Capacitor.getPlatform() !== 'web') {
      this.showCashMethod = false;
    }
    this.basketSubmit = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
    let expiry;
    if (this.basket.billingInfo.expiryyear && this.basket.billingInfo.expirymonth) {
      const year = this.basket.billingInfo.expiryyear.toString().substr(2, 2);
      let month = this.basket.billingInfo.expirymonth.toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      expiry = month + year;
    }

    this.addCreditCardForm = this.fb.group({
      cardNumber: [
        this.basket.billingInfo.cardnumber ? this.basket.billingInfo.cardnumber : '',
        [Validators.required, Validators.minLength(11)]
      ],
      expDate: [expiry ? expiry : '', [Validators.required, Validators.minLength(4)]],
      cvvCode: [
        this.basket.billingInfo.cvv ? this.basket.billingInfo.cvv : '',
        [Validators.required, Validators.minLength(3)]
      ],
      zipCode: [
        this.basket.billingInfo.zip ? this.basket.billingInfo.zip : '',
        [Validators.required, Validators.minLength(5)]
      ],
      save: false
    });

    this.addAdditionCreditCardForm = this.fb.group({
      cardNumber: ['', [Validators.required, Validators.minLength(11)]],
      expDate: ['', [Validators.required, Validators.minLength(4)]],
      cvvCode: ['', [Validators.required, Validators.minLength(3)]],
      zipCode: ['', [Validators.required, Validators.minLength(5)]]
    });

    this.addGiftCardForm = this.fb.group({
      cardNumber: ['', [Validators.required]],
      pinNumber: ['', Validators.required]
    });

    this.addSecondGiftCardForm = this.fb.group({
      cardNumber: ['', [Validators.required]],
      pinNumber: ['', Validators.required]
    });

    this.addThirdGiftCardform = this.fb.group({
      cardNumber: ['', [Validators.required]],
      pinNumber: ['', Validators.required]
    });

    this.couponPromoForm = this.fb.group({
      couponCode: ['']
    });

    this.tipForm = this.fb.group({
      customTip: [
        this.basket.basket.tip ? this.basket.basket.tip.toFixed(2) : null,
        [Validators.min(0), Validators.pattern('/[0-9]*/')]
      ]
    });

    this.basket$.pipe(first()).subscribe(() => {
      this.calculateTipPresets();
    });

    const basket: BasketStateModel = this.store.selectSnapshot(BasketState);
    if (basket && !basket.capacityPlaceholder) {
      this.store.dispatch(new SetOrderPlaceholder()).subscribe(newBasket => {
        this.startInterval(newBasket.basket.capacityPlaceholder.time);
      });
    } else {
      this.startInterval(basket.capacityPlaceholder.time);
    }

    if (basket && basket.giftCard) {
      this.giftTotal = this.gc.gcTotal(basket);
      if (basket.giftCard && basket.basketValidation.total > this.giftTotal) {
        this.noNeedAdditionPay = false;
      } else {
        this.noNeedAdditionPay = true;
      }
      this.paymentMethod.setValue('giftcard');
      if (basket.giftCard.length === 1) {
        this.addGiftCardForm.get('cardNumber').setValue(basket.giftCard[0].cardnumber);
        this.addGiftCardForm.get('pinNumber').setValue(basket.giftCard[0].pin);
      } else if (basket.giftCard.length === 2) {
        this.addNextGiftCard = true;
        this.addGiftCardForm.get('cardNumber').setValue(basket.giftCard[0].cardnumber);
        this.addGiftCardForm.get('pinNumber').setValue(basket.giftCard[0].pin);
        this.addSecondGiftCardForm.get('cardNumber').setValue(basket.giftCard[1].cardnumber);
        this.addSecondGiftCardForm.get('pinNumber').setValue(basket.giftCard[1].pin);
      }
      if (basket.extraCard) {
        this.addAdditionPayment = true;
        this.canaddExtraCard = false;
        this.addAdditionCreditCardForm.get('cardNumber').setValue(basket.extraCard.cardnumber);
        this.addAdditionCreditCardForm.get('expDate').setValue(basket.extraCard.expiry);
        this.addAdditionCreditCardForm.get('cvvCode').setValue(basket.extraCard.cvv);
        this.addAdditionCreditCardForm.get('zipCode').setValue(basket.extraCard.zip);
      }
    }
    if (basket.basket) {
      this.analytics.logCheckoutReview(basket.basket.products, basket.basket);
    }
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.setupWallets();
    }, 500);
    this.paymentMethod.valueChanges.subscribe(async value => {
      if (value === 'creditcard') {
        await this.setupWallets();
      }
    });
  }

  async setupWallets() {
    if (this.stripeInitializationFailures >= 5) {
      this.toastr.error('Could not initialize payment form. Please try again later.');
      return;
    }
    try {
      await this.cardElements.create(this.cardOptions);
      await this.setupDigitalWallets();
      this.stripeInitializationFailures = 0;
    } catch (error) {
      console.error(error);
      this.stripeInitializationFailures++;
      setTimeout(() => {
        this.setupWallets();
      }, 500);
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new RemoveOrderPlaceholder());
    clearInterval(this.intervalTimer);
  }

  startInterval(time: string) {
    this.intervalTimer = setInterval(() => {
      const diffTime = moment().diff(moment(time));
      const timeLimit = this.store.selectSnapshot(RestaurantState).settings.checkout_timeout_length;
      const timeoutTime = timeLimit * 60000;
      if (diffTime > timeoutTime) {
        this.timeLeft = null;
        clearInterval(this.intervalTimer);
        this.openTimeoutModal();
      } else {
        const remainingTime = moment(timeoutTime).diff(moment(diffTime));
        const seconds =
          moment
            .duration(remainingTime)
            .seconds()
            .toString().length === 1
            ? '0' + moment.duration(remainingTime).seconds()
            : moment.duration(remainingTime).seconds();
        this.timeLeft = moment.duration(remainingTime).minutes() + ':' + seconds;
      }
      this.timeLimitLoading = false;
    }, 1000);
  }

  async toggleShowing() {
    this.showing = !this.showing;
    await this.setupWallets();
  }

  async setupDigitalWallets() {
    if (Capacitor.getPlatform() === 'web') {
      await this.digitalWallets.initialize({
        options: {
          total: {
            label: 'Total',
            amount: Number(((this.basket.basket.total - this.giftTotal) * 100).toFixed(0))
          },
          currency: 'usd',
          country: 'US'
        }
      });
      this.digitalWallets.unmount();
      this.digitalWallets.mountButton(paymentMethod => {
        this.basketSubmit.token = paymentMethod.id;
        this.basketSubmit.cardtype = paymentMethod.card.brand;
        this.basketSubmit.cardlastfour = paymentMethod.card.last4;
        this.basketSubmit.billingmethod = 'digitalwallet';
        this.basketSubmit.billingschemeid = this.store
          .selectSnapshot((state: GlobalStateModel) => state.basket.billingSchemes)
          ?.find(
            (scheme: any) =>
              scheme.type === 'external' &&
              scheme.name === (paymentMethod.card.wallet?.type === 'google_pay' ? 'Google Pay' : 'Apple Pay')
          )?.id;
        this.basketSubmit.saveonfile = 'false';
        this.digitalWallets.completePaymentEvent();
        this.finishAndSubmit();
      });
    }
  }

  openTimeoutModal() {
    let modalRef;
    const modalOptions: NgbModalOptions = {
      centered: true,
      size: 'lg',
      windowClass: 'hideScroll',
      backdrop: 'static',
      keyboard: false
    };
    modalRef = this.modalService.open(TimeoutModalComponent, modalOptions);
    modalRef.componentInstance.continueClicked.subscribe(() => {
      this.modalService.dismissAll();
      this.router.navigateByUrl('/order-details');
    });
  }

  submitCreditCardEvent(form?: UntypedFormGroup) {
    this.creditCardSubmit.emit(this.addCreditCardForm);
  }

  submitGiftCardEvent(form?: UntypedFormGroup) {
    this.giftCardSubmit.emit(this.addGiftCardForm);
  }

  checkAndSwitchMask(input: any) {
    if (input.target.value.length > 17 && this.currentCardMask === this.amExMask) {
      this.currentCardMask = this.regularMask;
      this.currentCVVMask = '000';
    } else if (this.currentCardMask === this.regularMask && input.target.value.length < 19) {
      this.currentCardMask = this.amExMask;
      this.currentCVVMask = '0000';
    }
  }

  calculateTipPresets() {
    this.lowerTip = this.basket.basket.subtotal * 0.15;
    this.middleTip = this.basket.basket.subtotal * 0.2;
    this.upperTip = this.basket.basket.subtotal * 0.25;
    this.noTip = 0;
    this.calculateTip();
  }

  whatisselected() {
    // console.log('selected', this.selected);
  }

  customTipChanged(event: any) {
    let tipValue = event.target.value;
    tipValue = tipValue.replace(/[^0-9.]+/g, '');
    tipValue = Math.floor(tipValue);
    if (tipValue < 0) {
      tipValue = 0;
    }
    event.target.value = tipValue;
  }

  customTip() {
    if (!this.tipForm.get('customTip').value) {
      return;
    }
    const tipValue = String(this.tipForm.get('customTip').value);
    this.addTipToBasket(tipValue.replace('$', ''));
    // console.log('tip changed');
    // this.selected = 4;
    // const setValue = this.tipForm.get('customTip').value;
    // const noDollar = setValue.replace('$', '');
    // noDollar.replace(',', '');
    // if (noDollar.length > 0) {
    //   const newvalue = this.currencyPipe.transform(noDollar);
    //   this.tipForm.get('customTip').setValue(newvalue);
    //   const addToBasket = newvalue.replace('$', '');
    //   this.addTipToBasket(this.tipForm.get('customTip').value);
    // } else {
    //   const newnovalue = this.currencyPipe.transform(0);
    //   this.tipForm.get('customTip').setValue(newnovalue);
    //   this.addTipToBasket('0');
    // }
  }

  textFieldClicked() {
    this.selected = 4;
  }

  addTipToBasket(tip: string) {
    // if (this.shouldDivideTip) {
    //   const tipAmount = Number(tip);
    //   tip = String(Math.round(tipAmount / 100));
    //   this.shouldDivideTip = false;
    // }
    this.store.dispatch(new SetTipAmount(tip)).subscribe(
      res => {
        const basket: BasketStateModel = this.store.selectSnapshot(BasketState);

        this.tipForm.get('customTip').setValue(basket.basket.tip.toFixed(2));
        if (basket.giftCard && basket.giftCard.length > 0) {
          this.giftTotal = this.gc.gcTotal(basket);
          if (basket.giftCard && basket.basketValidation.total > this.giftTotal) {
            this.noNeedAdditionPay = false;
          } else {
            this.noNeedAdditionPay = true;
          }
        }
      },
      error => {
        const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket.basket);
        this.tipForm.get('customTip').setValue(basket.tip.toFixed(2));
        this.toastr.warning(error.error.message);
      }
    );
  }

  calculateTip(index?: number) {
    if (typeof index !== 'undefined') {
      this.selected = index;
    }
  }

  async continueToNextPage(isApplePay?: boolean, isGooglePay?: boolean) {
    // add condition for checking basket
    // const basket = this.store.selectSnapshot(BasketState);
    // if(basket.basket.products.length == 0){
    //   this.warning('An order requires at least one item');
    //   return;
    // }
    // add condition for typing error;
    this.isLoading = true;
    if (this.checkCapacityandOlo() === 1) {
      this.openCapacityError();
      return;
    }
    if (this.paymentMethod.value === 'creditcard' && this.billingAccountControl.value === 'default') {
      // const paymentMethod = await this.cardElements.createPaymentMethod({address: {postal_code: this.addCreditCardForm.value.zipCode}})
      // console.log(paymentMethod)
      // this.isLoading = false;
      // return
      // if (!this.addCreditCardForm.value.cardNumber || this.addCreditCardForm.value.cardNumber.length <= 14) {
      //   this.warning('Please complete your card number information.');
      //   this.isLoading = false;
      //   return;
      // } else if (!this.addCreditCardForm.value.cvvCode || this.addCreditCardForm.value.cvvCode.length < 3) {
      //   this.isLoading = false;
      //   this.warning('Please complete your CVV that can be found from the back of the card.');
      //   return;
      // } else if (!this.addCreditCardForm.value.expDate || this.addCreditCardForm.value.expDate.length < 4) {
      //   this.isLoading = false;
      //   this.warning('Please complete your card expiration information.');
      //   return;
      if (!this.addCreditCardForm.value.zipCode || this.addCreditCardForm.value.zipCode.length < 5) {
        this.isLoading = false;
        this.warning('Please complete your billing ZIP code. ');
        return;
      }
    } else if (this.paymentMethod.value === 'giftcard') {
      if (!this.addGiftCardForm.value.cardNumber || this.addGiftCardForm.value.cardNumber.length < 14) {
        this.warning('Please complete your gift card number.');
        return;
      }
    }
    // let userTip: number = Number(this.tipForm.value.customTip.substr(1));
    // if (this.tipForm.value.customTip === '') {
    //   userTip = 0;
    // }
    // if (userTip >= 100 || userTip < 0 || !Number.isInteger(userTip) || isNaN(userTip)) {
    //   this.warning('Please enter a tip from 0 to 100 whole dollars.');
    //   return;
    // }
    this.submitOrder(isApplePay, isGooglePay);
  }

  submitOrder(isApplePay?: boolean, isGooglePay?: boolean) {
    if (this.paymentMethod.value === 'giftcard') {
      if (!this.addAdditionPayment && !this.addNextGiftCard) {
        this.store.select(BasketState).subscribe(basket => {
          this.giftCard = basket.applyGiftCard;
        });
        if (this.giftCard) {
          this.giftCardPayment().subscribe(() => this.finishAndSubmit());
        } else {
          this.warning('Please Apply Gift Card First');
        }
      } else {
        this.splitPay
          .multiplePayment(
            this.giftTotal,
            this.addAdditionCreditCardForm,
            null,
            null,
            false,
            null,
            isApplePay,
            isGooglePay
          )
          .subscribe(
            () => {
              this.isLoading = false;
              this.toastr.success('The Order has been completed!');
              this.router.navigateByUrl('/confirm');
            },
            error => {
              this.isLoading = false;
              if (error.error && error.error.message) {
                this.toastr.warning(error.error.message);
              } else {
                this.toastr.warning(typeof error === 'string' ? error : error.message);
              }
            }
          );
        return;
      }
    } else if (this.paymentMethod.value === 'creditcard' && this.billingAccountControl.value === 'default') {
      this.store.dispatch(new ValidateBasket());
      from(this.singleCreditCardPayment()).subscribe(() => this.finishAndSubmit());
    } else if (this.paymentMethod.value === 'creditcard') {
      // type is credit card but a non-default value is selected for billingAccount
      this.savedCreditCardPayment().subscribe(() => this.finishAndSubmit());
    } else if (this.paymentMethod.value === 'payinstore') {
      this.payInStore().subscribe(() => this.finishAndSubmit());
    } else if (this.paymentMethod.value === 'Apple Pay' || this.paymentMethod.value === 'Google Pay') {
      this.mobileWalletPayment().subscribe(() => this.finishAndSubmit());
    } else {
      return;
    }
  }

  finishAndSubmit() {
    this.store.dispatch(new SetBillingInfo(this.basketSubmit)).subscribe(() => {
      this.submitBasket();
    });
  }

  checkCapacityandOlo() {
    // Make sure the Pizzas/salads in the basket align with the
    // pizzas/salads in our capacity manager
    // As well as pizzas/salads in our buckets
    const basket = this.store.selectSnapshot(BasketState);
    const capacity = basket.capacity._items;
    let capacityPizza: number;
    let capacitySalad: number;
    if (capacity.find((sche: any) => sche.categoryId === 100)) {
      capacityPizza = capacity.find((sche: any) => sche.categoryId === 100).products.length;
    } else {
      capacityPizza = 0;
    }
    if (capacity.find((sche: any) => sche.categoryId === 110)) {
      capacitySalad = capacity.find((sche: any) => sche.categoryId === 110).products.length;
    } else {
      capacitySalad = 0;
    }
    const olobasket = basket.basket.products;
    let oloPizza = 0;
    let oloSalad = 0;
    olobasket.forEach((element: any) => {
      if (element.customdata === '100') {
        oloPizza = oloPizza + element.quantity;
      }
      if (element.customdata === '110') {
        oloSalad = oloSalad + element.quantity;
      }
    });
    let bucketsPizza = 0;
    let bucketsSalad = 0;
    const capacityBuckets = basket.buckets;
    capacityBuckets.forEach((element: any) => {
      bucketsPizza = bucketsPizza + element.pizzas;
      bucketsSalad = bucketsSalad + element.salads;
    });
    if (
      capacityPizza === oloPizza &&
      oloPizza === bucketsPizza &&
      capacitySalad === oloSalad &&
      oloSalad === bucketsSalad
    ) {
      // Capacity manager and olo basket align
      return 0;
    } else {
      // Capacity manager and olo basket do not align
      // return 1 to stop order from going through.
      // let order go through as of right now.
      return 0;
    }
  }

  addPayment() {
    this.addAdditionPayment = !this.addAdditionPayment;
    if (this.addAdditionPayment) {
      this.additionText = 'cancel payment';
    } else {
      this.additionText = 'add additional credit card';
    }
    this.canaddExtraCard = false;
  }

  addGiftPayment() {
    this.addNextGiftCard = !this.addNextGiftCard;
  }

  savedCreditCardPayment(): Observable<BillingInfo> {
    return this.store.dispatch(new ValidateBasket()).pipe(
      map(() => {
        this.basketSubmit.billingmethod = 'billingaccount';
        this.basketSubmit.billingaccountid = this.billingAccountControl.value;
        return this.basketSubmit;
      })
    );
  }

  async singleCreditCardPayment(): Promise<BillingInfo> {
    await this.store.dispatch(new ValidateBasket()).toPromise();
    const paymentMethod = await this.cardElements.createPaymentMethod({
      address: { postal_code: this.addCreditCardForm.value.zipCode }
    });
    this.basketSubmit.billingmethod = 'creditcardtoken';
    this.basketSubmit.token = paymentMethod.paymentMethod.id;
    this.basketSubmit.cardtype = paymentMethod.paymentMethod.card.brand;
    this.basketSubmit.cardlastfour = paymentMethod.paymentMethod.card.last4;
    this.basketSubmit.zip = this.addCreditCardForm.value.zipCode;
    this.basketSubmit.country = paymentMethod.paymentMethod.card.country;
    this.basketSubmit.expiryyear = paymentMethod.paymentMethod.card.exp_year;
    this.basketSubmit.expirymonth = paymentMethod.paymentMethod.card.exp_month;
    this.basketSubmit.saveonfile = this.addCreditCardForm.value.save;
    return this.basketSubmit;
  }

  payInStore(): Observable<BillingInfo> {
    return this.store.dispatch(new ValidateBasket()).pipe(
      map(() => {
        this.basketSubmit.billingmethod = 'cash';
        return this.basketSubmit;
      })
    );
  }

  giftCardPayment(): Observable<BillingInfo> {
    return this.store.dispatch(new ValidateBasket()).pipe(
      map(() => {
        this.basketSubmit.billingmethod = 'storedvalue';
        const basket = this.store.selectSnapshot(BasketState);
        const bilingscheme = basket.billingSchemes.find((sche: any) => sche.type === 'giftcard').id;
        this.basketSubmit.billingschemeid = bilingscheme;
        this.basketSubmit.billingfields = [
          {
            name: 'number',
            value: this.addGiftCardForm.value.cardNumber
          },
          {
            name: 'pin',
            value: this.addGiftCardForm.value.pinNumber
          }
        ];
        this.basketSubmit.usertype = this.basket.billingInfo.usertype;
        return this.basketSubmit;
      })
    );
  }

  mobileWalletPayment(): Observable<BillingInfo> {
    return this.store.dispatch(new ValidateBasket()).pipe(
      switchMap(() => {
        return this.store
          .selectOnce(state => state.basket.basket)
          .pipe(
            switchMap((basket: Basket) => {
              return from(
                OloPaySDK.getDigitalWalletPaymentMethod({
                  currencyCode: 'USD',
                  countryCode: 'US',
                  amount: basket.total - this.giftTotal
                })
              ).pipe(
                map(res => {
                  this.basketSubmit.token = res.paymentMethod.id;
                  this.basketSubmit.cardtype = res.paymentMethod.cardType;
                  this.basketSubmit.cardlastfour = res.paymentMethod.last4;
                  this.basketSubmit.billingmethod = 'digitalwallet';
                  this.basketSubmit.billingschemeid = this.store
                    .selectSnapshot((state: GlobalStateModel) => state.basket.billingSchemes)
                    ?.find(
                      (scheme: any) =>
                        scheme.type === 'external' &&
                        scheme.name === (Capacitor.getPlatform() === 'ios' ? 'Apple Pay' : 'Google Pay')
                    )?.id;
                  return this.basketSubmit;
                })
              );
            })
          );
      })
    );
  }

  submitBasket() {
    this.store.dispatch(new SubmitBasket()).subscribe(
      async res => {
        this.success('The order has been completed!');
        try {
          if (this.basket.basket.deliverymode === 'pickup' && Capacitor.getPlatform() !== 'web') {
            const readyTime = this.readyTimeToDate(res.basket.previousBasket.readytime);
            // Calculate Time until order and send to Flutter
            const timeUntilOrder = readyTime.diff(moment(), 'minutes'); // In minutes
            const notifReadyTime = moment(readyTime);
            const notificationData = {
              ready: notifReadyTime,
              more15: timeUntilOrder > 15,
              date15: moment(notifReadyTime).subtract(15, 'minutes'),
              more5: timeUntilOrder > 5,
              date5: moment(notifReadyTime).subtract(5, 'minutes')
            };
            const permission = await LocalNotifications.checkPermissions();
            let updatedPermission = permission;
            if (permission.display !== 'granted') {
              updatedPermission = await LocalNotifications.requestPermissions();
            }
            if (updatedPermission.display === 'granted') {
              if (notificationData.more15) {
                await LocalNotifications.schedule({
                  notifications: [
                    // {
                    //   id: 0,
                    //   title: `Your ${notifReadyTime.format('h:mm A')} order will be ready for pickup soon!`,
                    //   body: `Please wait until ${notificationData.date5.format(
                    //     'h:mm A'
                    //   )} to enter the restaurant for pickup.`,
                    //   schedule: {
                    //     at: notificationData.date15.toDate(),
                    //     allowWhileIdle: true
                    //   },
                    //   largeIcon: 'ic_notif_large'
                    // },
                    {
                      id: 1,
                      title: `Your ${notifReadyTime.format('h:mm A')} order is coming up...`,
                      body: `Please look for the hanging “CHECK-IN” sign at the back of the restaurant and let us know your pickup name. Thank you`,
                      schedule: {
                        at: notificationData.date5.toDate(),
                        allowWhileIdle: true
                      },
                      largeIcon: 'ic_notif_large'
                    }
                  ]
                });
              } else if (notificationData.more5) {
                await LocalNotifications.schedule({
                  notifications: [
                    {
                      id: 0,
                      title: `Your ${notifReadyTime.format('h:mm A')} order is coming up...`,
                      body: `Please look for the hanging “CHECK-IN” sign at the back of the restaurant and let us know your pickup name. Thank you`,
                      schedule: {
                        at: notificationData.date5.toDate(),
                        allowWhileIdle: true
                      },
                      largeIcon: 'ic_notif_large'
                    }
                  ]
                });
              }
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          await this.router.navigate(['confirm']);
          this.store.dispatch(new EmptyValidation());
        }
      },
      error => {
        this.isLoading = false;
        this.basketSubmit = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
        if (error.error && error.error.message) {
          this.warning(error.error.message);
        } else if (error.message.includes('Http failure')) {
          this.warning(error.message);
          this.warning('There was a server error, please try again.');
        } else if (error.message) {
          this.warning(error.message);
        } else {
          this.warning(error);
        }
      }
    );
  }

  getDayTime(time: Date) {
    let halfDay = 'AM';
    let thisHour = time.getHours();
    if (thisHour >= 12) {
      halfDay = 'PM';
      thisHour -= 12;
    }
    if (thisHour === 0) {
      thisHour = 12;
    }
    let thisMinutes = String(time.getMinutes());
    if (thisMinutes.length <= 1) {
      thisMinutes = '0' + thisMinutes;
    }
    const thisTime = thisHour + ':' + thisMinutes + ' ' + halfDay;

    return thisTime;
  }

  readyTimeToDate(time: string): Moment {
    return DateTime.fromOlo(time).moment;
  }

  applyGiftCard() {
    this.store.dispatch(new ApplyGiftCard(true)).subscribe(
      () => {
        const basket = this.store.selectSnapshot(BasketState);
        this.giftTotal = this.gc.gcTotal(basket);
        if (basket.giftCard && basket.basketValidation.total > this.giftTotal) {
          this.noNeedAdditionPay = false;
        } else {
          this.noNeedAdditionPay = true;
        }
      },
      error => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  // Remove gift card showing from checkout-bag upon switching to another payment method
  removeGiftCard() {
    const basket = this.store.selectSnapshot(BasketState);
    if (basket.giftCard) {
      this.store.dispatch(new ApplyGiftCard(false));
    }
  }

  removeGiftCardIndex(index: number) {
    const basket = this.store.selectSnapshot(BasketState);
    this.store.dispatch(new RemoveGiftCard(index));
    if (index === 0 && basket.giftCard.length === 1) {
      this.addGiftCardForm.get('cardNumber').setValue('');
      this.addGiftCardForm.get('pinNumber').setValue('');
    } else if (index === 0 && basket.giftCard.length === 2) {
      this.addGiftCardForm.get('cardNumber').setValue(this.addSecondGiftCardForm.get('cardNumber').value);
      this.addGiftCardForm.get('pinNumber').setValue(this.addSecondGiftCardForm.get('pinNumber').value);
      this.addSecondGiftCardForm.get('cardNumber').setValue('');
      this.addSecondGiftCardForm.get('pinNumber').setValue('');
      this.addNextGiftCard = !this.addNextGiftCard;
    } else if (index === 1) {
      this.addSecondGiftCardForm.get('cardNumber').setValue('');
      this.addSecondGiftCardForm.get('pinNumber').setValue('');
    }
    const baskett = this.store.selectSnapshot(BasketState);
    let giftTotal = 0;
    if (baskett && baskett.giftCard) {
      baskett.giftCard.forEach((card: any) => {
        giftTotal = giftTotal + card.balance;
      });
    }
    this.giftTotal = giftTotal;
    if (baskett.giftCard && baskett.basketValidation.total > giftTotal) {
      this.noNeedAdditionPay = false;
    } else {
      this.noNeedAdditionPay = true;
    }
    if (baskett && baskett.giftCard) {
      if (baskett.giftCard.length < 2) {
        this.addNextGiftCard = false;
      }
    }
  }

  applyCoupon() {
    const coupon = this.couponPromoForm.value;
    this.store.dispatch(new ApplyCoupon(coupon.couponCode)).subscribe(
      res => {
        this.toastr.success('Coupon Applied');
        this.store.dispatch(new ValidateBasket());
      },
      error => {
        if (error.error.message) {
          this.toastr.warning(error.error.message);
        } else {
          this.toastr.warning('Coupon Code Is Invalid');
        }
      }
    );
  }

  checkBalance(index: number) {
    this.showBalance = true;
    const temp = this.gc.gcDetails(index, this.addGiftCardForm, this.addSecondGiftCardForm);
    const basket = this.store.selectSnapshot(BasketState);
    const cardAdded = this.gc.checkIfCardAdded(basket, temp);
    if (cardAdded) {
      return;
    }
    this.store.dispatch(new CheckBalance(temp)).subscribe(
      res => {
        const baskett = this.store.selectSnapshot(BasketState);
        let balance = 0;
        if (baskett && baskett.giftCard && baskett.giftCard[index] && baskett.giftCard[index].balance) {
          balance = baskett.giftCard[index].balance;
        }
        if (balance > 0) {
          this.store.dispatch(new ApplyGiftCard(true)).subscribe(() => {
            this.giftTotal = this.gc.gcTotal(baskett);
            if (baskett.giftCard && basket.basketValidation.total > this.giftTotal) {
              this.noNeedAdditionPay = false;
            } else {
              this.noNeedAdditionPay = true;
            }
          });
        } else {
          this.error('There are no funds on this gift card');
        }
        this.balance = balance;
        // this.success('Gift Card Balance is $' + balance);
      },
      error => {
        this.showBalance = false;
        this.error(error.error.message);
      }
    );
  }

  hideBottomCard() {
    return;
    if (window.innerWidth < 992) {
      document.getElementById('bottomCard').hidden = true;
    }
  }

  showBottomCard() {
    return;
    if (window.innerWidth < 992) {
      document.getElementById('bottomCard').hidden = false;
    }
  }

  success(msg: string) {
    this.toastr.success(msg, 'Success!', {
      timeOut: 2500
    });
    Haptics.notification({
      type: NotificationType.Success
    });
  }

  error(msg: string) {
    this.isLoading = false;
    this.toastr.error(msg, 'Error', {
      timeOut: 2500
    });
    Haptics.notification({
      type: NotificationType.Error
    });
  }
  warning(msg: string) {
    this.isLoading = false;
    console.log('usertype is ' + this.basket.billingInfo.usertype);
    this.toastr.warning(msg, 'Warning', {
      timeOut: 2500
    });
    Haptics.notification({
      type: NotificationType.Warning
    });
  }

  openCapacityError() {
    let modalRef;
    const modalOptions: NgbModalOptions = { backdrop: 'static', centered: true, size: 'lg', windowClass: 'hideScroll' };
    modalRef = this.modalService.open(CapacityErrorComponent, modalOptions);
    modalRef.componentInstance.xClicked.subscribe((res: any) => {
      this.modalService.dismissAll();
    });
    modalRef.componentInstance.restart.subscribe((res: any) => {
      this.modalService.dismissAll();
      this.router.navigateByUrl('/locations');
    });
  }

  protected readonly Capacitor = Capacitor;
}
