<div class="container" *ngIf="(user$ | async).billing?.billingaccounts as savedCards; else noCards">
  <ng-container *ngIf="savedCards.length; else noCards">
    <div
      class="d-flex flex-lg-row flex-column flex-wrap justify-content-center justify-content-lg-start align-items-center"
    >
      <div
        class="col savedCardCard position-relative d-flex flex-column  align-items-center p-3 gap-3 rounded-1"
        *ngFor="let card of savedCards | savedCardDedupe"
        align="center"
      >
        <div class="d-flex flex-row justify-content-between w-100">
          <div class="col savedCardType">{{ card.cardtype }} {{ card.isdefault ? ' (default)' : '' }}</div>
          <div class="col savedCardBrand">#{{ card.accountid }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col noPaddingCol">
            <div class="cardNumber">{{ card.description }}</div>
          </div>
        </div>
        <div *ngIf="card && card.expiration" class="d-flex flex-row justify-content-start">
          <div class="col noPaddingCol">
            <div class="exp">
              exp. {{ card.expiration.split('-')[1] }}/{{ card.expiration.split('-')[0].substring(2, 4) }}
            </div>
          </div>
        </div>

        <div class="d-flex flex-row position-absolute" style="bottom: 0">
          <div class="col" style="height: 65px">
            <div class="d-flex flex-row">
              <div class="col">
                <lib-button
                  text="REMOVE CARD"
                  [disabled]="accountLoading === card.accountid"
                  (buttonClick)="removeCard(card.accountidstring)"
                ></lib-button>
              </div>
            </div>
            <div *ngIf="!card.isdefault" class="d-flex flex-row">
              <div class="col">
                <lib-link text="make default" (linkClick)="makeDefault(card.accountidstring)"></lib-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noCards>
  <div class="d-flex flex-row">
    <div class="col-lg-8 col-md-12 noAccountInfo">
      <div class="accountDetailsInnerInfo">
        <div class="accountDetailsHeader">No Saved Cards</div>
        <div class="noAccountInfoLine"></div>
      </div>
    </div>

    <div class="col-lg-4 col-md-0"></div>
  </div>
</ng-template>
