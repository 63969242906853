<div class="d-flex flex-row">
  <div class="col-lg-8 col-md-12 checkoutDetailsLeftCol" *ngIf="(basket$ | async).basket as basket">
    <div class="d-flex flex-row py-2 text-center time-remaining-bar">
      <div class="col" *ngIf="!timeLimitLoading; else timeloading">
        Time Selected Guaranteed for {{ timeLeft ? timeLeft : '0:00' }}
      </div>
      <ng-template #timeloading>
        <div class="col">
          <div class="spin-loader"></div>
        </div>
      </ng-template>
    </div>
    <div class="insideInfo">
      <p class="secondaryHeader leftAlign">ORDER DETAILS</p>
      <div class="lineBorder mb-3"></div>
      <ng-container *ngIf="restaurant$ | async as restaurant">
        <lib-location-info [location]="restaurant" [locationMobile]="true" [isOrderReview]="true"> </lib-location-info>
      </ng-container>
      <ng-container *ngIf="basket.timemode === 'manualfire'; else regularTime">
        <ng-container *ngIf="(basket$ | async).manualFireTime as manualFireTime">
          <div class="bold mt-2">Order Time: {{ manualFireTime | oloDate | date: 'shortTime' }}</div>
        </ng-container>
      </ng-container>
      <ng-template #regularTime>
        <div class="bold mt-2">Order Time: {{ basket.timewanted | oloDate | date: 'shortTime' }}</div>
      </ng-template>

      <p class="secondaryHeader leftAlign">PAYMENT METHOD</p>
      <div class="lineBorder"></div>
      <div class="d-flex flex-row leftAlign">
        <div class="col-12 my-4 p-0 d-flex flex-column">
          <ng-container *ngIf="basket$ | async as basket">
            <div *ngIf="basket.billingSchemes && basket.billingSchemes.length; else loadingSchemes">
              <div
                *ngFor="let scheme of basket.billingSchemes"
                class="d-flex flex-row m-0"
                [ngStyle]="{ order: inputOrder[scheme.type] }"
              >
                <ng-container *ngIf="scheme.type !== 'external'; else externalPayments">
                  <ng-container *ngIf="!(scheme.type === 'payinstore' && !showCashMethod)">
                    <div class="col-12 p-0">
                      <div class="d-flex flex-row align-items-center w-auto">
                        <input
                          [id]="scheme.type"
                          class="clickable w-auto"
                          type="radio"
                          name="paymentType"
                          [value]="scheme.type"
                          [formControl]="paymentMethod"
                          (change)="removeGiftCard()"
                        />
                        <label [for]="scheme.type" class="radioButtonText clickable w-auto">{{ scheme.name }}</label>
                      </div>
                      <div *ngIf="paymentMethod.value === scheme.type" class="d-flex flex-column mb-2">
                        <ng-container
                          *ngTemplateOutlet="schemeInput; context: { billingScheme: scheme }"
                        ></ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #externalPayments>
                  <ng-container *ngIf="scheme.name === 'Apple Pay' && digitalWalletsReady && supportsApplePay">
                    <div class="col-12 p-0">
                      <div class="d-flex flex-row align-items-center w-auto">
                        <input
                          [id]="scheme.type"
                          class="clickable w-auto"
                          type="radio"
                          name="paymentType"
                          [value]="scheme.name"
                          [formControl]="paymentMethod"
                          (change)="removeGiftCard()"
                        />
                        <div class="d-flex align-items-start flex-row flex-nowrap">
                          <label [for]="scheme.type" class="radioButtonText clickable w-auto"
                            >{{ scheme.name }}&nbsp;</label
                          >
                          <i class="nf nf-dev-apple" style="padding-top: 2px;"></i>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="scheme.name === 'Google Pay' && digitalWalletsReady && supportsGooglePay">
                    <div class="col-12 p-0">
                      <div class="d-flex flex-row align-items-center w-auto">
                        <input
                          [id]="scheme.type"
                          class="clickable w-auto"
                          type="radio"
                          name="paymentType"
                          [value]="scheme.name"
                          [formControl]="paymentMethod"
                          (change)="removeGiftCard()"
                        />
                        <div class="d-flex align-items-start flex-row flex-nowrap">
                          <label [for]="scheme.type" class="radioButtonText clickable w-auto"
                            >{{ scheme.name }}&nbsp;</label
                          >
                          <i class="nf nf-fa-google" style="padding-top: 2px;"></i>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
            <ng-template #loadingSchemes>
              <div class="col">
                <div class="spin-loader-black"></div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
      <div class="lineBorder"></div>

      <div *ngIf="basket.allowstip">
        <p class="deliveryTipText mt-3">
          Optional Tip
        </p>
        <div class="mobileCheckoutCard">
          <p class="smallHeader">{{ tipText }}</p>
          <div class="smallHeaderRule"></div>
          <div class="col-12 p-0">
            <div class="d-flex flex-row tipArea">
              <div class="col-3 p-0 customTipWeb">
                <form [formGroup]="tipForm" id="tipField">
                  <div class="input-group mb-3">
                    <span class="input-group-text textField bg-white border-end-0 p-2">$</span>
                    <input
                      type="text"
                      formControlName="customTip"
                      mask="0*.00"
                      class="form-control textField border-start-0 ps-0"
                      aria-label="Tip Amount"
                      inputmode="decimal"
                      [dropSpecialCharacters]="false"
                      (click)="textFieldClicked()"
                      (keyup.enter)="customTip()"
                      (blur)="customTip(); showBottomCard()"
                      (focus)="customTip(); hideBottomCard()"
                    />
                  </div>
                  <!--                                    <lib-single-text-field-->
                  <!--                                      label="$0"-->
                  <!--                                      inputType="text"-->
                  <!--                                      controlName="customTip"-->
                  <!--                                      [dropSpecialCharacters]="false"-->
                  <!--                                      inputMask="0*.00"-->
                  <!--                                      [parentForm]="tipForm"-->
                  <!--                                      (click)="textFieldClicked()"-->
                  <!--                                      (keyup.enter)="customTip()"-->
                  <!--                                      (blurClicked)="customTip(); showBottomCard()"-->
                  <!--                                      (focusClicked)="customTip(); hideBottomCard()"-->
                  <!--                                      inputMode="decimal"-->
                  <!--                                    >-->
                  <!--                                    </lib-single-text-field>-->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lineBorderWeb"></div>
      <div class="d-flex d-lg-none flex-column col-12 px-0 couponCodeMobile gap-3">
        <div class="lineBorder flex-grow-1 w-100"></div>
        <div class="d-flex flex-row flex-nowrap gap-3 w-100">
          <div class="p-0 couponTextField flex-grow-1">
            <form [formGroup]="couponPromoForm">
              <lib-single-text-field
                label="Coupon / Promo Code"
                inputType="text"
                couponCode="true"
                controlName="couponCode"
                [parentForm]="couponPromoForm"
                (blurClicked)="showBottomCard()"
                (focusClicked)="hideBottomCard()"
              >
              </lib-single-text-field>
            </form>
          </div>
          <div class="couponButton pe-0" style="min-width: 100px;">
            <lib-button (click)="applyCoupon()" large="true" text="APPLY"></lib-button>
          </div>
        </div>
      </div>
      <div class="leftAlign" style="margin-top: 30px; margin-bottom: 30px">
        <div class="col-12 p-0">
          <div class="d-lg-flex d-none flex-row couponCodeWeb gap-3">
            <div class="flex-grow-1 p-0 couponTextField">
              <form [formGroup]="couponPromoForm">
                <lib-single-text-field
                  label="Coupon / Promo Code"
                  inputType="text"
                  couponCode="true"
                  controlName="couponCode"
                  [parentForm]="couponPromoForm"
                  (focusClicked)="hideBottomCard()"
                  (blurClicked)="showBottomCard()"
                >
                </lib-single-text-field>
              </form>
            </div>
            <div class="couponButton pe-0" style="min-width: 100px;">
              <lib-button (click)="applyCoupon()" large="true" text="APPLY"></lib-button>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: left;">
        Your order will be placed once you enter your payment information and click “Place Order”. No further
        modifications can be made after your order has been placed.
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-sm-12 orderDetailsRightCol" id="bottomCard">
    <div *ngIf="(basket$ | async).basket as basket">
      <ng-container>
        <app-checkout-bag
          [basket]="basket"
          isPayment="true"
          (nextPageEvent)="continueToNextPage()"
          [isOrderDetails]="false"
          [isLoading]="isLoading"
          [paymentType]="paymentMethod.value"
        ></app-checkout-bag>
      </ng-container>
    </div>
  </div>
</div>

<!-- //ADDITIONAL CREDIT CARD FUNCTIONALITY -->
<ng-template #additionCreditForm>
  <div
    *ngIf="digitalWalletsReady && supportsApplePay"
    style="display: inline-block;-webkit-appearance: -apple-pay-button; -apple-pay-button-type: finish; -apple-pay-button-style: black; width: 100%; height: 40px; border-radius: 4px"
    lang="en"
    (click)="continueToNextPage(true, false)"
  ></div>
  <button
    *ngIf="digitalWalletsReady && supportsGooglePay"
    type="button"
    aria-label="Google Pay"
    class="gpay-button black order en"
    (click)="continueToNextPage(false, true)"
  ></button>
  <form [formGroup]="addAdditionCreditCardForm">
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <p>{{ this.errorMessage }}</p>
      </div>
    </div>

    <div class="d-flex flex-row">
      <div class="col-9 p-0">
        <lib-single-text-field
          label="Card Number"
          inputType="text"
          [inputMask]="currentCardMask"
          controlName="cardNumber"
          [parentForm]="addAdditionCreditCardForm"
          (changeInputEvent)="checkAndSwitchMask($event)"
          (focusClicked)="hideBottomCard()"
          (blurClicked)="showBottomCard()"
          inputMode="numeric"
        >
        </lib-single-text-field>
        <!--        <div data-olo-pay-card-number class="form-control textField d-flex flex-row flex-nowrap align-items-center"></div>-->
      </div>
      <div class="col-3 p-0">
        <lib-single-text-field
          label="CVV"
          inputType="text"
          [inputMask]="currentCVVMask"
          controlName="cvvCode"
          [parentForm]="addAdditionCreditCardForm"
          (focusClicked)="hideBottomCard()"
          (blurClicked)="showBottomCard()"
          inputMode="numeric"
        >
        </lib-single-text-field>
        <!--        <div data-olo-pay-card-cvv class="form-control textField d-flex flex-row flex-nowrap align-items-center"></div>-->
      </div>
    </div>

    <div class="d-flex flex-row">
      <div class="col p-0">
        <lib-single-text-field
          label="Exp. Date"
          inputType="text"
          inputMask="00/00"
          controlName="expDate"
          [parentForm]="addAdditionCreditCardForm"
          (blurClicked)="showBottomCard()"
          (focusClicked)="hideBottomCard()"
          inputMode="numeric"
        >
        </lib-single-text-field>
        <!--        <div data-olo-pay-card-expiry class="form-control textField d-flex flex-row flex-nowrap align-items-center"></div>-->
      </div>
      <div class="col p-0">
        <lib-single-text-field
          label="Zip"
          inputType="text"
          [inputMask]="currentZIPMask"
          controlName="zipCode"
          [parentForm]="addAdditionCreditCardForm"
          (focusClicked)="hideBottomCard()"
          (blurClicked)="showBottomCard()"
          inputMode="numeric"
        >
        </lib-single-text-field>
      </div>
    </div>
  </form>
</ng-template>

<!-- //ADDITIONAL GIFT CARD FUNCTIONALITY -->
<ng-template #additionGiftCard>
  <form [formGroup]="addSecondGiftCardForm">
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <lib-single-text-field
          label="Card Number"
          inputType="text"
          controlName="cardNumber"
          [parentForm]="addSecondGiftCardForm"
          (blurClicked)="showBottomCard()"
          (focusClicked)="hideBottomCard()"
          inputMode="numeric"
        >
        </lib-single-text-field>
      </div>
    </div>

    <div class="d-flex flex-row p-0">
      <div class="col-lg-8 col-md-7 col-sm-6 p-0">
        <lib-single-text-field
          label="6-Digit Security Pin"
          inputType="text"
          controlName="pinNumber"
          [parentForm]="addSecondGiftCardForm"
          (focusClicked)="hideBottomCard()"
          (blurClicked)="showBottomCard()"
          inputMode="numeric"
          bottomText="(Pin under silver scratch-off)"
        >
        </lib-single-text-field>
      </div>
      <div class="col-lg-4 col-md-5 col-sm-6" *ngIf="basket$ | async as basket">
        <lib-button
          (click)="checkBalance(1)"
          *ngIf="basket && basket.giftCard && !basket.giftCard[1]"
          large="true"
          text="APPLY CARD"
        ></lib-button>
        <lib-button
          (click)="removeGiftCardIndex(1)"
          *ngIf="basket && basket.giftCard && basket.giftCard[1]"
          large="true"
          text="REMOVE"
        ></lib-button>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center">
      <div class="col p-0" style="margin-bottom:10px;font-size: 19px;">
        <ng-container *ngIf="(basket$ | async).giftCard as giftCardNext">
          <span *ngIf="showBalance"><strong>Card Balance: </strong>{{ giftCardNext[1]?.balance | currency }}</span>
        </ng-container>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #schemeInput let-scheme="billingScheme">
  <ng-container *ngIf="scheme.type === 'creditcard'">
    <ng-container *ngTemplateOutlet="creditcard; context: { billingScheme: scheme, showing: false }"></ng-container>
  </ng-container>
  <ng-container *ngIf="scheme.type === 'payinstore'">
    <ng-container *ngTemplateOutlet="payinstore; context: { billingScheme: scheme }"></ng-container>
  </ng-container>
  <ng-container *ngIf="scheme.type === 'giftcard'">
    <ng-container *ngTemplateOutlet="giftcard; context: { billingScheme: scheme }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #creditcard let-scheme="billingScheme">
  <div *ngIf="scheme.accounts?.length" class="col-12 p-0">
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <select
          (change)="showing = false"
          class="form-select timeDropdown my-3"
          id="account-select"
          [formControl]="billingAccountControl"
        >
          <option disabled value="default" [selected]="'default'">Saved Credit Cards</option>
          <option *ngFor="let account of scheme.accounts" [value]="account.accountid">
            {{ account.description }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="!scheme.accounts?.length || showing" class="col-12 p-0">
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <form [formGroup]="addCreditCardForm">
          <div class="d-flex flex-row">
            <div class="col-12 p-0">
              <p>{{ this.errorMessage }}</p>
            </div>
          </div>
          <div class="d-flex flex-row mb-4">
            <div class="col-9 ps-0 pe-3">
              <!--              <lib-single-text-field-->
              <!--                id="ccNumber"-->
              <!--                label="Card Number"-->
              <!--                inputType="text"-->
              <!--                [inputMask]="currentCardMask"-->
              <!--                controlName="cardNumber"-->
              <!--                [parentForm]="addCreditCardForm"-->
              <!--                (changeInputEvent)="checkAndSwitchMask($event)"-->
              <!--                (blurClicked)="showBottomCard()"-->
              <!--                (focusClicked)="hideBottomCard()"-->
              <!--                inputMode="numeric"-->
              <!--              >-->
              <!--              </lib-single-text-field>-->
              <div
                data-olo-pay-card-number
                class="form-control textField d-flex flex-row flex-nowrap align-items-center"
              ></div>
            </div>
            <div class="col-3 p-0">
              <!--              <lib-single-text-field-->
              <!--                id="ccCVV"-->
              <!--                label="CVV"-->
              <!--                inputType="text"-->
              <!--                [inputMask]="currentCVVMask"-->
              <!--                controlName="cvvCode"-->
              <!--                [parentForm]="addCreditCardForm"-->
              <!--                (focusClicked)="hideBottomCard()"-->
              <!--                (blurClicked)="showBottomCard()"-->
              <!--                inputMode="numeric"-->
              <!--              >-->
              <!--              </lib-single-text-field>-->
              <div
                data-olo-pay-card-cvv
                class="form-control textField d-flex flex-row flex-nowrap align-items-center"
              ></div>
            </div>
          </div>

          <div class="d-flex flex-row gap-3">
            <div class="col ps-0">
              <!--              <lib-single-text-field-->
              <!--                id="ccExpDate"-->
              <!--                label="Exp. Date"-->
              <!--                inputType="text"-->
              <!--                inputMask="00/00"-->
              <!--                controlName="expDate"-->
              <!--                [parentForm]="addCreditCardForm"-->
              <!--                (blurClicked)="showBottomCard()"-->
              <!--                (focusClicked)="hideBottomCard()"-->
              <!--                inputMode="numeric"-->
              <!--              >-->
              <!--              </lib-single-text-field>-->
              <div
                data-olo-pay-card-expiry
                class="form-control textField d-flex flex-row flex-nowrap align-items-center"
              ></div>
            </div>
            <div class="col p-0">
              <lib-single-text-field
                id="ccZip"
                label="Zip"
                inputType="text"
                [inputMask]="currentZIPMask"
                controlName="zipCode"
                [parentForm]="addCreditCardForm"
                (focusClicked)="hideBottomCard()"
                (blurClicked)="showBottomCard()"
                inputMode="numeric"
              >
              </lib-single-text-field>
            </div>
          </div>
          <ng-container *ngIf="Capacitor.getPlatform() === 'web'">
            <div data-olo-pay-payment-button></div>
          </ng-container>
          <div *ngIf="(user$ | async).info?.authtoken" class="d-flex flex-row">
            <div class="col-12 save-card">
              <input type="checkbox" formControlName="save" />
              <span>{{ saveCardText }}</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="scheme.accounts?.length" class="col-12 p-0">
    <lib-button
      large="true"
      [ghost]="true"
      [text]="showing ? 'HIDE FORM' : 'ADD ANOTHER PAYMENT METHOD'"
      (buttonClick)="toggleShowing(); billingAccountControl.setValue('default')"
    ></lib-button>
  </div>
</ng-template>

<ng-template #payinstore let-scheme="billingScheme"></ng-template>

<ng-template #giftcard let-scheme="billingScheme">
  <div class="col-12 p-0">
    <form [formGroup]="addGiftCardForm">
      <div class="d-flex flex-row">
        <div class="col-12">
          <p>{{ this.errorMessage }}</p>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12 p-0">
          <lib-single-text-field
            label="Card Number"
            inputType="text"
            controlName="cardNumber"
            [parentForm]="addGiftCardForm"
            (blurClicked)="showBottomCard()"
            (focusClicked)="hideBottomCard()"
            inputMode="numeric"
          >
          </lib-single-text-field>
        </div>
      </div>

      <div class="d-flex flex-row p-0 gap-3">
        <div class="flex-grow-1 p-0">
          <lib-single-text-field
            label="6-Digit Security Pin"
            inputType="text"
            controlName="pinNumber"
            [parentForm]="addGiftCardForm"
            (focusClicked)="hideBottomCard()"
            (blurClicked)="showBottomCard()"
            inputMode="numeric"
            bottomText="(Pin under silver scratch-off)"
          >
          </lib-single-text-field>
        </div>
        <div class="flex-grow-1" *ngIf="basket$ | async as basket">
          <lib-button
            (click)="checkBalance(0)"
            *ngIf="basket && !basket.giftCard"
            large="true"
            text="APPLY CARD"
          ></lib-button>
          <lib-button
            (click)="removeGiftCardIndex(0)"
            *ngIf="basket && basket.giftCard && basket.giftCard[0]"
            large="true"
            text="REMOVE"
          ></lib-button>
        </div>
      </div>

      <div class="d-flex flex-row align-items-center">
        <div class="col p-0" style="margin-bottom:10px;font-size: 19px;">
          <ng-container *ngIf="(basket$ | async).giftCard as giftCard">
            <span *ngIf="showBalance"><strong>Card Balance: </strong>{{ giftCard[0].balance | currency }}</span>
          </ng-container>
        </div>
      </div>
    </form>

    <!-- //2nd ADDITIONAL PAYMENT -->
    <ng-container>
      <div class="lineBorder"></div>
      <div class="p-0">
        <div *ngIf="addNextGiftCard">
          <div>
            <p class="secondaryHeader">Add Payment - Gift Card</p>
            <p *ngIf="basket.giftCard && basket.basketValidation">
              Remaining Amount Due:
              <span>
                {{
                  basket.basketValidation.total - giftTotal <= 0
                    ? 0
                    : (basket.basketValidation.total - giftTotal | currency)
                }}</span
              >
            </p>
          </div>
          <ng-container *ngTemplateOutlet="additionGiftCard"></ng-container>
        </div>
        <div *ngIf="addAdditionPayment">
          <div>
            <p class="secondaryHeader">Add Payment - Credit Card</p>
            <p *ngIf="basket.giftCard && basket.basketValidation">
              Remaining Amount Due:
              <span>
                {{
                  basket.basketValidation.total - basket.giftCard.balance <= 0
                    ? 0
                    : (basket.basketValidation.total - basket.giftCard.balance | currency)
                }}</span
              >
            </p>
          </div>
          <ng-container *ngTemplateOutlet="additionCreditForm"></ng-container>
        </div>
      </div>
      <div class="d-flex flex-column use-gift-card align-items-center" *ngIf="!noNeedAdditionPay">
        <div class="w-100 save-card p-0">
          <lib-button
            [disabled]="noNeedAdditionPay && basket.basketValidation.total > giftTotal"
            *ngIf="!addNextGiftCard"
            ghost="true"
            large="true"
            (click)="addGiftPayment()"
            text="{{ giftText }}"
          ></lib-button>
        </div>
        <div class="w-100 save-card p-0 mt-3">
          <lib-button
            [disabled]="noNeedAdditionPay && basket.basketValidation.total > giftTotal"
            *ngIf="canaddExtraCard"
            ghost="true"
            (click)="addPayment()"
            large="true"
            text="{{ additionText }}"
          ></lib-button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
