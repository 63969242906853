import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  AbstractControl,
  FormGroup,
  FormBuilder,
  FormArray,
  ValidationErrors,
  FormControl
} from '@angular/forms';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '@app/store/state.model';
import { Observable } from 'rxjs';
import { GetContactListsResponse, Record as ContactList } from '@app/models/wisely/get-contact-lists.interface';

@Component({
  selector: 'lib-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styles: []
})
export class SignUpFormComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.contactLists) contactLists$: Observable<GetContactListsResponse>;

  @Input() isCheckout = false;
  @Input() emailListText = 'Check here to join The Punch List for updates, deals and more!';
  @Input() termsAndConditionsText = 'Accept Terms and Conditions';
  @Input() createAccountButtonText = 'CREATE ACCOUNT';
  @Input() signInButtonText = 'SIGN IN';
  @Input() continueAsGuestText = 'CONTINUE AS GUEST';

  @Output() signUpSubmitEvent = new EventEmitter<any>(); // Adjust the type as needed
  @Output() leavePageEvent = new EventEmitter<string>();

  createAccountForm: FormGroup;
  errorMessage = '';
  contactLists: ContactList[] = []; // Define the ContactList interface based on your API response

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createAccountForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      passwords: this.fb.group(
        {
          password: ['', Validators.required],
          confirm: ['', Validators.required]
        },
        { validators: this.passwordConfirming }
      ),
      phoneNumber: ['', [Validators.required, Validators.pattern(/\(\d{3}\) \d{3}-\d{4}/)]],
      contactLists: new FormArray([]), // Initialize the FormArray
      termsAndConditions: [false, Validators.requiredTrue] // Added for terms and conditions
    });

    // Subscribe to contactLists$ to populate contactLists and the FormArray
    this.contactLists$.subscribe(
      response => {
        if (response && response.records) {
          this.contactLists = response.records;
          this.addContactListCheckboxes();
        }
      },
      error => {
        console.error('Error fetching contact lists:', error);
      }
    );
  }

  // Custom validator to check if passwords match
  passwordConfirming(formGroup: AbstractControl): ValidationErrors | null {
    const password = formGroup.get('password')?.value;
    const confirm = formGroup.get('confirm')?.value;
    return password === confirm ? null : { invalid: true };
  }

  // Getter for easy access to contactLists FormArray
  get contactListsFormArray() {
    return this.createAccountForm.get('contactLists') as FormArray;
  }

  // Dynamically add a checkbox for each contact list
  private addContactListCheckboxes() {
    this.contactLists.forEach(() => this.contactListsFormArray.push(new FormControl(false)));
  }

  // Handle form submission
  submitForm() {
    if (this.createAccountForm.invalid) {
      this.createAccountForm.markAllAsTouched();
      return;
    }

    const selectedContactListIds = this.createAccountForm.value.contactLists
      .map((checked: boolean, index: number) => (checked ? this.contactLists[index].id : null))
      .filter((id: number | null) => id !== null);

    const formData = {
      emailAddress: this.createAccountForm.value.emailAddress,
      firstName: this.createAccountForm.value.firstName,
      lastName: this.createAccountForm.value.lastName,
      password: this.createAccountForm.value.passwords.password,
      phoneNumber: this.createAccountForm.value.phoneNumber,
      contactListIds: selectedContactListIds,
      termsAndConditions: this.createAccountForm.value.termsAndConditions
    };

    console.log('Form Data:', formData);
    this.signUpSubmitEvent.emit(formData);
  }

  // Emit events for navigation
  clickEvent(identifier?: string) {
    this.leavePageEvent.emit(identifier);
  }
}
