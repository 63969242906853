import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStateModel } from '@app/store/state/user.state';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UpdatePassword, UpdateUser, UpdateUserPreferences, UpsertWiseGuest } from '@app/store/actions/user.action';
import { Router } from '@angular/router';
import { User } from '../../models/olo.user';
import { ToastrService } from 'ngx-toastr';
import { UserCommunicationPreferences } from '../../models/olo.usercommunicationpreferences';
import { BooleanString } from '../../models/BooleanString';
import { GlobalStateModel } from '@app/store/state.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteAccountModalComponent } from '@app/components/delete-account-modal/delete-account-modal.component';
import { GetContactListsResponse } from '@app/models/wisely/get-contact-lists.interface';
import { filter } from 'rxjs/operators';
import { UpsertGuestRequest } from '@app/models/wisely/upsert-guest.interface';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'lib-account-details',
  templateUrl: './account-details.component.html',
  styles: []
})
export class AccountDetailsComponent implements OnInit {
  @Input() buttons = true;
  @Input() basketCreated = true;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['individual', 'group', 'catering'];

  @Select() user$: Observable<UserStateModel>;
  @Select((state: GlobalStateModel) => state.user.contactLists) contactLists$: Observable<GetContactListsResponse>;

  passwordChangeCollapsed = true;
  editEmailCollapsed = true;
  changePasswordForm: UntypedFormGroup;
  editEmailForm: UntypedFormGroup;
  contactForm: FormGroup;
  contactLists: any[] = []; // Stores the API response records

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private router: Router,
    private toastService: ToastrService,
    private modal: NgbModal
  ) {
    this.contactForm = this.fb.group({
      contactLists: new FormArray([])
    });
  }

  ngOnInit() {
    this.changePasswordForm = this.resetPasswordForm();
    this.editEmailForm = this.resetEmailForm();
    this.fetchContactLists();
  }
  clickEvent(event: any) {}

  passwordConfirming(formGroup: AbstractControl): { invalid: boolean } {
    if (formGroup.get('password').value !== formGroup.get('confirm').value) {
      if (formGroup.get('password').touched && formGroup.get('password').touched) {
      }
      return { invalid: true };
    }
  }

  updatePassword() {
    // call to dispatch action to change password
    const currentPassword = this.changePasswordForm.get('currentPassword').value;
    const newPassword = this.changePasswordForm.get('newPasswords').get('password').value;
    this.store.dispatch(new UpdatePassword(currentPassword, newPassword)).subscribe(
      res => {
        if (res) {
          // console.log( 'Password updated' );
          this.toastService.success('Password Updated');
        }
      },
      (error: any) => {
        if (error.error && error.error.code === 200) {
          this.toastService.warning(error.error.message);
        } else if (error.error) {
          this.toastService.error(error.error.message);
        } else {
          this.toastService.error(error.message);
        }
      }
    );
    this.changePasswordForm = this.resetPasswordForm();
  }

  updateEmail() {
    const newEmail = this.editEmailForm.get('newEmail').value;
    const info: User = { ...this.store.selectSnapshot(state => state.user.info) };
    info.emailaddress = newEmail;
    info.firstname = this.editEmailForm.get('newFirstName').value;
    info.lastname = this.editEmailForm.get('newLastName').value;
    const wiselyUpdate: UpsertGuestRequest = {
      contact_list_ids: this.contactForm.value.contactLists
        .map((checked: boolean, index: number) => (checked ? this.contactLists[index].id : null))
        .filter((id: number | null) => id !== null),
      identifiers: {
        email: info.emailaddress
      },
      guest: {
        fname: info.firstname,
        lname: info.lastname
      },
      creation_source: Capacitor.getPlatform()
    };
    this.store
      .dispatch([
        new UpdateUser(info),
        new UpdateUserPreferences(this.editEmailForm.get('newEmailOptIn').value),
        new UpsertWiseGuest(wiselyUpdate)
      ])
      .subscribe(
        (res: any) => {
          // Next
          this.toastService.success('Account Info Updated');
          this.editEmailCollapsed = true;
        },
        (error: any) => {
          if (error.error && error.error.code === 200) {
            this.toastService.warning(error.error.message);
          } else if (error.error) {
            this.toastService.error(error.error.message);
          } else {
            this.toastService.error(error.message);
          }
        }
      );
    this.editEmailForm = this.resetEmailForm();
  }

  resetPasswordForm(): UntypedFormGroup {
    return this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPasswords: this.fb.group(
        {
          password: ['', Validators.required],
          confirm: ['', Validators.required]
        },
        { validator: this.passwordConfirming }
      )
    });
  }

  resetEmailForm(): UntypedFormGroup {
    const info: User = { ...this.store.selectSnapshot((state: GlobalStateModel) => state.user.info) };
    const preferences: UserCommunicationPreferences = {
      ...this.store.selectSnapshot((state: GlobalStateModel) => state.user.userPreferences)
    };
    return this.fb.group({
      newEmail: [info.emailaddress, [Validators.required, Validators.email]],
      newFirstName: [info.firstname, [Validators.required]],
      newLastName: [info.lastname, [Validators.required]],
      newEmailOptIn: [preferences.optin === BooleanString.True, [Validators.required]]
    });
  }

  openDeleteAccountModal() {
    this.modal.open(DeleteAccountModalComponent, {
      centered: true,
      keyboard: false
    });
  }

  fetchContactLists() {
    this.contactLists$.pipe(filter(res => !!res)).subscribe(res => {
      this.contactLists = res.records;
      this.addCheckboxes();
    });
  }

  // Dynamically add a checkbox for each contact list
  private addCheckboxes() {
    this.contactLists.forEach(() => this.contactListsFormArray.push(new FormControl(false)));
  }

  // Getter for easy access to contactLists FormArray
  get contactListsFormArray() {
    return this.contactForm.get('contactLists') as FormArray;
  }
}
