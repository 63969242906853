<div class="pastOrderShell">
  <div class="d-flex flex-row mb-4" style="display: flex; justify-content: space-between">
    <div class="d-flex flex-column justify-content-start align-items-start ps-4">
      <h5 class="pastOrderHeader">{{ order.deliverymode | handoff }} ORDER</h5>
      <span class="text-muted">({{ order.vendorname }})</span>
    </div>

    <a class="close clickable" aria-label="Close" style="text-align: right;" (click)="xClick()">
      <div class="popup-x-modal">
        <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
      </div>
    </a>
  </div>

  <div class="pastOrders">
    <div class="pastOrderItem" *ngFor="let product of order.products">
      <div class="d-flex flex-row pastOrderPopupHeaderRow">
        <div class="col pastOrderPopupName">
          {{ product.name }}
        </div>
        <div class="col pastOrderPopupPrice">
          {{ product.totalcost | currency }}
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col-8 d-flex flex-wrap flex-row" style="margin-right: auto; display: flex;">
          <span
            class="small text-muted"
            style="text-align: left"
            *ngFor="let choice of product.choices | cleanChoices; index as i"
          >
            {{ choice.name }}{{ i < product.choices.length - 1 ? ', ' : '' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="pastOrderPopupBottom pb-3">
    <div class="d-flex flex-row pastOrderPopupTotalRow">
      <div class="col pastOrderPopupTotal">
        Total
      </div>
      <div class="col pastOrderPopupTotalPrice">
        {{ order.total | currency }}
      </div>
    </div>
    <div class="d-flex flex-row pastOrderPopupButton">
      <div class="col">
        <lib-button (buttonClick)="reorderClick()" large="true" text="REORDER"></lib-button>
      </div>
    </div>
  </div>
</div>
