<div class="container-fluider" style="padding-top: env(safe-area-inset-top, 0)">
  <div class="expo-form">
    <!-- Header Section with Location Selection -->
    <div class="d-flex" flex-row>
      <div class="col" *ngIf="isMaster$ | async">
        <select id="locationSelect" (change)="locationSelectChange($event)" [value]="selectedLocationId">
          <option *ngFor="let heatmap of availableLocations" [value]="heatmap.restaurantID">
            {{ heatmap.locationName }}
          </option>
        </select>
      </div>
      <div class="col ms-auto current-btn" *ngIf="!(isMaster$ | async)">
        <button class="returnToKioskBtn btn py-2 pe-2 ps-1" [disabled]="!hasScrolled" (click)="scrollBack()">
          Current &nbsp; &nbsp;
          <i class="far fa-clock me-2"></i>
        </button>
      </div>
    </div>

    <!-- Heatmap Display Section -->
    <div class="kioskCont mt-0 pb-5">
      <div class="d-flex flex-row">
        <h3 class="text-center mx-auto my-0 f-20">Capacity Heat Map</h3>
      </div>

      <!-- Display Selected Heatmap Location -->
      <div *ngIf="selectedHeatmapLocation">
        <div style="margin: 8px 0;" [ngClass]="{ 'master-margin': isMaster$ | async }">
          <div class="d-flex flex-row pb-0">
            <h3 class="text-center text-uppercase f-20 mx-auto my-0">
              {{ selectedHeatmapLocation.locationName }}
            </h3>
          </div>

          <!-- Heatmap Content -->
          <div
            *ngIf="
              selectedHeatmapLocation.capacityBuckets && selectedHeatmapLocation.capacityBuckets.length;
              else showLoader
            "
          >
            <!-- Time Slots -->
            <div class="ribbon-items" (scroll)="ribbonScroll($event)" id="ribbon-scroll">
              <table id="timeSlots" class="table table-striped table-sm mb-1" width="100%">
                <thead>
                  <tr class="times pt-1" style="width:0">
                    <ng-container *ngFor="let slot of selectedHeatmapLocation.timeStamps; index as i">
                      <ng-container *ngIf="i === 0; else timeSlot">
                        <div>
                          <span style="white-space: nowrap">{{ slot | ShortTimePipe | date: 'h:mm aaaaa' }}</span>
                          <i class="fas fa-caret-down"></i>
                        </div>
                      </ng-container>
                      <ng-template #timeSlot>
                        <span>{{ slot | ShortTimePipe | date: 'h:mm aaaaa' }}</span>
                      </ng-template>
                    </ng-container>
                  </tr>
                </thead>
              </table>

              <!-- Capacity Buckets -->
              <table id="timeRibbon" class="table table-striped table-sm">
                <thead>
                  <tr class="buckets" style="width:0">
                    <th
                      *ngFor="let slot of selectedHeatmapLocation.capacityBuckets"
                      [ngClass]="'th-color-' + slot.pizzas"
                      class="test-color"
                    >
                      {{ slot.pizzas }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <!-- Capacity and Sales Cards -->
            <div class="card-container">
              <!-- First Row: 1-3 Pizzas and Next Hour -->
              <div class="d-flex flex-row">
                <div
                  class="col-3 px-2 py-0"
                  *ngFor="let slot of selectedHeatmapLocation.upcomingSlots.slice(0, 3); let i = index"
                >
                  <div class="card">
                    <div class="card-body p-2" *ngIf="slot; else noSlot">
                      <h5 class="card-title">{{ i + 1 }} Pizza{{ i > 0 ? 's' : '' }}</h5>
                      <p class="card-text">
                        {{ slot.key | intervalToMinutesRemaining: selectedHeatmapLocation.restaurantID | async }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-3 px-2 py-0">
                  <div class="card pizza-card">
                    <div class="card-body p-2">
                      <h5 class="card-title pizza-amt">{{ selectedHeatmapLocation.nextHourQuant }}</h5>
                      <p class="card-text pizza-subhead">Next Hour</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Second Row: 4-6 Pizzas and Rest of Day -->
              <div class="d-flex flex-row">
                <div
                  class="col-3 px-2 py-0"
                  *ngFor="let slot of selectedHeatmapLocation.upcomingSlots.slice(3, 6); let i = index"
                >
                  <div class="card">
                    <div class="card-body p-2" *ngIf="slot; else noSlot">
                      <h5 class="card-title">{{ i + 4 }} Pizza{{ i > 0 ? 's' : '' }}</h5>
                      <p class="card-text">
                        {{ slot.key | intervalToMinutesRemaining: selectedHeatmapLocation.restaurantID | async }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-3 px-2 py-0">
                  <div class="card pizza-card">
                    <div class="card-body p-2">
                      <h5 class="card-title pizza-amt">{{ selectedHeatmapLocation.restOfDayQuant }}</h5>
                      <p class="card-text pizza-subhead">Rest of Day</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Order Type Buttons for Non-Master Users -->
              <ng-container *ngIf="!(isMaster$ | async)">
                <div class="d-flex flex-row pt-3 pb-3 justify-content-center">
                  <div class="w-100 pe-2 text-end my-auto">
                    <button
                      id="kioskDineInBtn"
                      class="btn expoGhostButton f-18 w-100 largeButton"
                      (click)="guestInfoModal('dinein', selectedHeatmapLocation)"
                    >
                      <div *ngIf="isLoading !== 'dinein'">
                        Digital Dine-In
                      </div>
                      <div class="spin-loader-ghost" *ngIf="isLoading === 'dinein'"></div>
                    </button>
                  </div>
                  <div class="w-100 ps-2 text-start my-auto">
                    <button
                      class="btn btn-primary text-white f-18 w-100 largeButton"
                      (click)="guestInfoModal('pickup', selectedHeatmapLocation)"
                    >
                      <div *ngIf="isLoading !== 'pickup'">Take Out</div>
                      <div class="spin-loader-ghost" *ngIf="isLoading === 'pickup'"></div>
                    </button>
                  </div>
                </div>
              </ng-container>

              <!-- GF Left Display -->
              <div
                *ngIf="selectedHeatmapLocation.gfLeft !== null"
                class="d-flex flex-row text-center mb-3"
                style="align-items: center; justify-content: center;"
              >
                <div class="col-3 px-2 py-0">
                  <div class="card">
                    <div class="card-body p-2" style="height: auto;">
                      <p class="card-title pizza-amt">
                        GF LEFT: <span class="enzo-remaining">{{ selectedHeatmapLocation.gfLeft }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- No Slot Template -->
            <ng-template #noSlot>
              <div class="card-body p-2">
                <p class="card-text">Empty</p>
              </div>
            </ng-template>
          </div>

          <!-- Loader Template -->
          <ng-template #showLoader>
            <div class="d-flex flex-row">
              <div class="col">
                <div class="spin-loader-heat-map"></div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
