import { NgModule } from '@angular/core';
import { BagItemComponent } from './bag-item.component';
import { CommonModule } from '@angular/common';
import { IncrementorModule } from '../incrementor/incrementor.module';
import { LinkModule } from '../link/link.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [BagItemComponent],
  imports: [CommonModule, IncrementorModule, LinkModule, SharedModule],
  exports: [BagItemComponent]
})
export class BagItemModule {}
