<div class="container-fluid" *ngIf="savedProducts$ | async as savedProducts">
  <ng-container *ngIf="savedProducts.length > 0; else noSavedProducts">
    <div class="d-flex flex-row favoriteOrdersRow gap-3 flex-wrap">
      <ng-container *ngFor="let product of savedProducts">
        <lib-saved-product-card
          [product]="product"
          [defaultImageUrl]="defaultImageUrl"
          text="Quick Add"
          (addToBag)="addSavedProductToCart($event, maxOrder)"
          [isLoading]="selectedProdID === product.id"
        >
        </lib-saved-product-card>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #noSavedProducts>
  <div class="d-flex flex-row">
    <div class="col-lg-8 col-md-12 noAccountInfo">
      <div class="accountDetailsInnerInfo">
        <div class="accountDetailsHeader">No Saved Products</div>
        <div class="noAccountInfoLine"></div>
      </div>
    </div>

    <div class="col-lg-4 col-md-0"></div>
  </div>
</ng-template>

<ng-template class="pup" #maxOrder let-modal>
  <lib-max-pizza-modal (xClicked)="modal.dismiss()"></lib-max-pizza-modal>
</ng-template>
