<div class="arrow button-left d-flex d-lg-none" (click)="scrollLeft()" [ngClass]="{ hidden: isCarouselAtLeftEdge() }">
  <i class="nf nf-oct-arrow_left"></i>
</div>

<nav
  class="navbar subnav navbar-expand navbar-light menubar hideScroll px-3"
  id="subnav"
  #navbarNavAltMarkup
  style="padding-bottom: 0"
>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup" style="justify-content: center">
    <div class="navbar-nav subnav-inner">
      <a
        *ngFor="let link of links; index as i"
        [ngStyle]="{
          'border-bottom': selected === i ? '5px solid #F04E29' : 'none',
          'text-transform': capital ? 'uppercase' : 'none'
        }"
        class="nav-item nav-link active subnav-item"
        (click)="clicked(i)"
        href="javascript:void(0)"
        [attr.data-cy]="link"
      >
        <span class="menulinks" [ngClass]="selected !== i ? 'text-muted' : ''">{{ link }}</span>
      </a>
    </div>
  </div>
</nav>

<div
  class="arrow button-right d-flex d-lg-none"
  (click)="scrollRight()"
  [ngClass]="{ hidden: isCarouselAtRightEdge() }"
>
  <i class="nf nf-oct-arrow_right"></i>
</div>
