import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BasketStateModel } from '@app/store/state/basket.state';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetBanner } from '@app/store/actions/app.action';
import { DateTime } from 'src/assets/chepri-modules/src/models/DateTime';
import { PrinterService } from '@app/providers/expo/printer/printer.service';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import {
  CleanGiftCard,
  ClearChange,
  ClearExtraCard,
  ClearTrancloudCardData,
  SetBillingInfo
} from '@app/store/actions/basket.action';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { EmailService } from '@app/providers/expo/email/email.service';
import { filter } from 'rxjs/operators';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { BasketChoice, BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { CapacityService } from '@app/providers/capacity.service';
import { DirectusCurrentOrder } from '@app/models/capacity.model';
import { orderstatusproduct } from '@lib/models/olo.recentorders';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class KioskConfirmationComponent implements AfterViewInit, OnDestroy {
  @Select() basket$: Observable<BasketStateModel>;

  basket: BasketStateModel;
  expoOrder: any;
  receiptPrinted = false;

  subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private printer: PrinterService,
    private currencyPipe: CurrencyPipe,
    private expo: DirectusExpoProvider,
    private email: EmailService,
    private truncatePipe: TruncatePipe,
    private capacityService: CapacityService
  ) {
    this.store.dispatch(new SetBanner('CONFIRMATION', true, false, ''));
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.basket$.pipe(filter(b => b.basket !== null && b.previousBasket !== null)).subscribe(basket => {
        this.basket = basket;
        this.expo.getExpoOrderByOrderId(basket.previousBasket.id).subscribe(res => {
          this.expoOrder = res[0];
          const emailAddress = localStorage.getItem('emailAddress');
          localStorage.removeItem('emailAddress');
          if (emailAddress && emailAddress !== '') {
            this.email
              .postConfirmation({ expoOrder: this.expoOrder, basket: this.basket, emailAddress })
              .subscribe(() => {});
          }
          if (!this.receiptPrinted) {
            this.printer.onSendMessageCanvas(this.generateReceipt.bind(this));
            this.receiptPrinted = true;
          }
        });
      })
    );
    this.deleteOldCapacity();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.store.dispatch(new ClearChange());
    this.store.dispatch(new ClearTrancloudCardData());
    this.store.dispatch(new CleanGiftCard()); // make it all nice and shiny, then obliterate it immediately
    this.store.dispatch(new ClearExtraCard());
    this.store.dispatch(new SetBillingInfo(null));
  }

  startNewOrder() {
    this.router.navigateByUrl('/kiosk/start-order');
  }

  getTime(oloTime: string): string {
    return DateTime.fromOlo(oloTime).time;
  }

  deleteOldCapacity() {
    this.capacityService.getOldCapacityOrders().subscribe((orders: DirectusCurrentOrder[]) => {
      orders.forEach(o => {
        this.capacityService.deleteOldCapacityOrders(o.id.toString()).subscribe(() => {});
      });
    });
  }

  generateReceipt() {
    console.log('Date Time');
    const formattedDateTime = moment(
      this.basket.previousBasket.deliverymode === 'dinein'
        ? this.expoOrder.ready_time
        : this.basket.previousBasket.readytime,
      'YYYY-MM-DD HH:mm:ss'
    ).format('M/D/YYYY - h:mm a');
    console.log(formattedDateTime);

    const cardData = this.store.selectSnapshot(state => state.basket.trancloudInfo);
    const formattedSubtotal = this.currencyPipe.transform(this.basket.previousBasket.subtotal, 'USD');
    let formattedDiscount = '';

    if (this.basket.previousBasket.discounts && this.basket.previousBasket.discounts.length) {
      formattedDiscount = this.currencyPipe.transform(this.basket.previousBasket.discounts[0].amount, 'USD');
    }

    const formattedTax = this.currencyPipe.transform(this.basket.previousBasket.salestax, 'USD');
    const formattedTotal = this.currencyPipe.transform(this.basket.previousBasket.total, 'USD');

    // Add initial line spacing
    this.printer.drawLineSpace(3);

    // Centered vendor name
    this.printer.drawAlignedText('center', this.basket.previousBasket.vendorname);
    this.printer.drawLineSpace(2);

    // Centered customer name
    this.printer.drawAlignedText('center', this.basket.billingInfo.firstname + ' ' + this.basket.billingInfo.lastname);
    this.printer.drawLineSpace(1);

    // Centered order number
    this.printer.drawAlignedText('center', 'Order #' + this.basket.previousBasket.oloid);
    this.printer.drawLineSpace(1);

    // Centered date and time
    this.printer.drawAlignedText('center', formattedDateTime);
    this.printer.drawLineSpace(3);

    // Iterate over products
    this.basket.previousBasket.products.forEach(product => {
      const formattedName = this.truncatePipe.transform(product.name, 27);
      const formattedPrice = this.currencyPipe.transform(product.totalcost, 'USD');

      this.printer.drawLeftRightText(`${product.quantity} - ${formattedName}`, formattedPrice);
      this.printer.drawLineSpace(1);

      // Iterate over product choices
      if (product.choices && product.choices.length) {
        product.choices.forEach(choice => {
          if (
            !choice.name.includes('Included') &&
            choice.name !== 'Additional Toppings' &&
            choice.name !== 'Additional Ingredients' &&
            choice.name !== 'Mozzarella (Standard, No Charge)'
          ) {
            this.printer.drawAlignedText('left', `     * ${this.truncatePipe.transform(choice.name, 30)}`);
            this.printer.drawLineSpace(1);
          }
        });
      }
    });

    // Additional line spacing
    this.printer.drawLineSpace(1);
    this.printer.drawLineSpace(2);

    // Print Subtotal
    this.printer.drawLeftRightText('Subtotal:', formattedSubtotal);
    this.printer.drawLineSpace(1);

    // Print Discount if available
    if (this.basket.previousBasket.discounts && this.basket.previousBasket.discounts.length) {
      this.printer.drawLeftRightText('Discount:', '-' + formattedDiscount);
      this.printer.drawLineSpace(1);
    }

    // Print Tax
    this.printer.drawLeftRightText('Tax:', formattedTax);
    this.printer.drawLineSpace(1);

    // Print Tip if available
    if (this.basket.previousBasket.tip) {
      this.printer.drawLeftRightText('Tip:', this.currencyPipe.transform(this.basket.previousBasket.tip, 'USD'));
      this.printer.drawLineSpace(1);
    }

    // Print Total
    this.printer.drawLeftRightText('Total:', formattedTotal);
    this.printer.drawLineSpace(3);

    // Print Card Data if available
    if (cardData) {
      this.printer.drawLeftRightText(cardData.cardType, cardData.cardNumber);
      this.printer.drawLineSpace(1);
      this.printer.drawLeftRightText(`  Auth: ${cardData.authCode}`, '');
      this.printer.drawLineSpace(1);
      this.printer.drawLeftRightText(`  Ref #: ${cardData.refNo}`, '');
      this.printer.drawLineSpace(2);
    }

    // Print Payment Method

    const paymentMethods =
      (this.basket.giftCard !== null
        ? 'GIFT CARD'
        : this.basket.billingInfo.billingmethod || this.basket.extraCard
        ? 'CASH'
        : 'CREDIT CARD') +
      (this.basket.extraCard
        ? ' / CREDIT CARD'
        : this.basket.giftCard !== null &&
          this.basket.giftCard.length === 1 &&
          this.basket.giftCard[0].balance < this.basket.previousBasket.total
        ? ' / CASH'
        : '');

    console.log('Payment method(s): ' + paymentMethods);
    console.log('Basket: ', this.basket);

    this.printer.drawLeftRightText('Paid with:', paymentMethods);
    this.printer.drawLineSpace(2);
  }
}
