import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PastOrdersComponent } from './past-orders.component';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { IconModule } from '../icon/icon.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { PastOrderModalModule } from '../past-order-modal/past-order-modal.module';
import { MaxPizzaModalModule } from '@lib/lib/max-pizza-modal/max-pizza-modal.module';
import { SharedModule } from '@app/shared';
@NgModule({
  declarations: [PastOrdersComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LinkModule,
    IconModule,
    SvgSpriteModule,
    PastOrderModalModule,
    MaxPizzaModalModule,
    SharedModule
  ],
  exports: [PastOrdersComponent]
})
export class PastOrdersModule {}
