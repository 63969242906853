import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetContactListsResponse } from '@app/models/wisely/get-contact-lists.interface';
import { UpsertGuestRequest, UpsertGuestResponse } from '@app/models/wisely/upsert-guest.interface';
import { GetGuestResponse } from '@app/models/wisely/get-guest-info.interface';

@Injectable({
  providedIn: 'root'
})
export class WiselyService {
  baseURL = environment.wiselyURL;

  constructor(private http: HttpClient) {}

  getContactLists(): Observable<GetContactListsResponse> {
    const resource = '/contactLists';
    return this.http.get<GetContactListsResponse>(this.baseURL + resource, { responseType: 'json', observe: 'body' });
  }

  upsertGuest(data: UpsertGuestRequest): Observable<UpsertGuestResponse> {
    const resource = '/upsertGuest';
    return this.http.post<UpsertGuestResponse>(this.baseURL + resource, data, {
      responseType: 'json',
      observe: 'body'
    });
  }

  getGuestInfo(email: string): Observable<GetGuestResponse> {
    const resource = '/guest';
    const params = new HttpParams({
      fromObject: {
        email
      }
    });
    return this.http.get<GetGuestResponse>(this.baseURL + resource, { params, responseType: 'json', observe: 'body' });
  }
}
