import { State, Action, StateContext, Store } from '@ngxs/store';
import { tap, concatMap, first, switchMap, map, catchError, retry } from 'rxjs/operators';
import { Basket, BasketTransfer, BasketValidation } from 'src/assets/chepri-modules/src/models/olo.basket';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import {
  CreateBasket,
  AddProductToBasket,
  RemoveProductFromBasket,
  UpdateSingleProductInBasket,
  SetTimeWanted,
  CreateFromOrder,
  ValidateBasket,
  SubmitBasket,
  EmptyBasket,
  SetTipAmount,
  AddToCategoryLimit,
  RemoveFromCategoryLimit,
  EmptyLimits,
  SetBillingInfo,
  EmptyPreviousBasket,
  EmptyValidation,
  SetHandoffMode,
  SetDispatchAddress,
  GetBillingSchemes,
  CreateFromFaves,
  ApplyCoupon,
  CheckBalance,
  CleanGiftCard,
  SubmitBasketMultiPay,
  ApplyGiftCard,
  GetBasket,
  GetTimeSlots,
  SetTimeWantedToAsap,
  SetBuckets,
  SetIncrementor,
  TransferBasket,
  AddReorderToLimit,
  RemoveGiftCard,
  SaveExtraCreditCard,
  AddUpsellToBasket,
  AddManualFireTime,
  SetChange,
  ClearChange,
  SubmitTrancloudBasket,
  ClearTrancloudCardData,
  StoreTrancloudCardData,
  SetOrderPlaceholder,
  RemoveOrderPlaceholder,
  ClearExtraCard,
  RemoveCoupon,
  SetCustomField,
  AddSavedProductToBasket,
  SetReferralToken
} from '../actions/basket.action';
import { BasketService } from '@app/providers/basket.service';
import { Limiters, Limiter } from 'src/assets/chepri-modules/src/models/Limiter';
import { BasketChoice, BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { orderstatus } from 'src/assets/chepri-modules/src/models/olo.recentorders';
import { UserState, UserStateModel } from './user.state';
import { FavoritesOrderPayloadModel } from '../../../assets/chepri-modules/src/lib/favorite-order-name/favorites-order.model';
import { GetRecentOrders } from '../actions/user.action';
import { RestaurantState } from './restaurant.state';
import { CapacityService } from '@app/providers/capacity.service';
import { SelectableTime, TimeSlot } from '@app/models/capacity.model';
import { ToastrService } from 'ngx-toastr';
import { of, concat, throwError, Observable, combineLatest } from 'rxjs';
import * as moment from 'moment';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { Preferences } from '@capacitor/preferences';
import { ModeService } from '@app/providers/mode.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SwipeCardModalComponent } from '@app/components/swipe-card-modal/swipe-card-modal.component';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { Injectable } from '@angular/core';
import { CapacityPlaceholder } from 'src/assets/chepri-modules/src/models/capacityplaceholder';
import { Order } from 'src/assets/chepri-modules/src/models/olo.order';
import { TrancloudErrorLoggingService } from '@app/datacap-trancloud/services/trancloud-error-logging.service';
import { DateTime } from '@lib/models/DateTime';
import { AnalyticsService } from '@app/providers/analytics.service';

export interface BasketStateModel {
  basket: Basket;
  billingInfo: BillingInfo;
  capacity: Limiters;
  buckets: SelectableTime[];
  timeSlots: TimeSlot[];
  previousBasket: Order;
  basketValidation: BasketValidation;
  billingSchemes: any;
  giftCard: any;
  applyGiftCard: any;
  incrementor: any;
  extraCard: any;
  manualFireTime: any;
  change: number;
  trancloudInfo: {
    cardNumber: string;
    cardType: string;
    authCode: string;
    refNo: string;
  };
  capacityPlaceholder: CapacityPlaceholder;
}

@State<BasketStateModel>({
  name: 'basket',
  defaults: {
    basket: null,
    billingInfo: new BillingInfo(),
    capacity: new Limiters([]),
    buckets: null,
    timeSlots: null,
    previousBasket: null,
    basketValidation: null,
    billingSchemes: null,
    giftCard: null,
    applyGiftCard: null,
    incrementor: null,
    extraCard: null,
    manualFireTime: null,
    change: null,
    trancloudInfo: null,
    capacityPlaceholder: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class BasketState {
  basketSubmit: BillingInfo = new BillingInfo();
  isLoading = false;

  constructor(
    private basketService: BasketService,
    private store: Store,
    private userState: UserState,
    private capacityService: CapacityService,
    private toast: ToastrService,
    private expo: DirectusExpoProvider,
    private mode: ModeService,
    private modalService: NgbModal,
    private tranCloud: TrancloudProviderService,
    private toastr: ToastrService,
    private errorLogging: TrancloudErrorLoggingService,
    private analytics: AnalyticsService
  ) {}

  @Action(CreateBasket)
  createBasket({ patchState }: StateContext<BasketStateModel>, { locationId, authToken }: CreateBasket) {
    if (!authToken) {
      const userState: UserStateModel = this.store.selectSnapshot(state => state.user);
      if (userState && userState.info && userState.info.authtoken) {
        authToken = userState.info.authtoken;
      }
    }
    return this.basketService.createBasket(locationId, authToken).pipe(
      tap(
        res => {
          const basket = res;
          Preferences.set({ key: 'punchBasket', value: basket.id });
          patchState({ basket });
          this.store.dispatch(new EmptyValidation());
          this.store.dispatch(new EmptyLimits());
          if (sessionStorage.getItem('rwg_token')) {
            this.store.dispatch(new SetReferralToken());
          }
        }
        // error => {
        //   this.toast.warning(error.error.message);
        // }
      )
    );
  }

  @Action(GetBasket)
  async getBasket({ getState, patchState }: StateContext<BasketStateModel>, { payload }: GetBasket) {
    const bId = await Preferences.get({ key: 'punchBasket' });
    let basketId = bId.value;
    if (getState().basket) {
      basketId = getState().basket.id;
    }
    // const basketId = getState().basket.id;
    return this.basketService
      .getBasket(basketId)
      .pipe(
        first(),
        tap((basket: any) => {
          patchState({ basket });
        })
      )
      .subscribe();
  }

  @Action(GetTimeSlots)
  getTimeSlots({ getState, patchState }: StateContext<BasketStateModel>, { isFirstLoad, deliveryMode }: any) {
    const capacitySettings = this.store.selectSnapshot(state => state.restaurant).capacity_settings;
    const earliestRT = getState().basket.earliestreadytime;
    const end = this.store.selectSnapshot(state => state.restaurant).calendar.calendar[0].ranges[0].end;
    const limiters = getState().capacity;
    const vendorId = getState().basket.vendorid;
    const delivMode = deliveryMode ? deliveryMode : getState().basket.deliverymode;
    const checkBuzzCapacity: boolean = delivMode === 'pickup' && localStorage.getItem('modeKey') === 'order';
    return this.capacityService
      .getAvailableTimeSlots(capacitySettings, earliestRT, end, vendorId, limiters, isFirstLoad, checkBuzzCapacity)
      .pipe(
        tap(timeSlots => {
          patchState({
            timeSlots
          });
        })
      );
  }

  @Action(SetBuckets)
  setBuckets({ patchState }: StateContext<BasketStateModel>, { buckets, isManualFire }: SetBuckets) {
    patchState({
      buckets
    });
    this.store.dispatch(new SetTimeWanted(buckets[buckets.length - 1].time, isManualFire));
  }

  @Action(CreateFromOrder)
  createFromOrder({ patchState }: StateContext<BasketStateModel>, payload: CreateFromOrder) {
    return this.basketService.createBasketFromOrder(payload.orderRef, payload.id, payload.token).pipe(
      tap(
        res => {
          const basket = res;
          patchState({ basket });
          this.store.dispatch(new GetTimeSlots(true));
        }
        // error => {
        //   this.toast.warning(error.error.message);
        // }
      )
    );
  }

  @Action(CreateFromFaves)
  CreateFromFaves(ctx: StateContext<BasketStateModel>, { payload }: { payload: FavoritesOrderPayloadModel }) {
    return this.basketService.reorderFavoriteOrder(payload.id, payload.token).pipe(
      tap(basket => {
        ctx.setState({
          ...ctx.getState(),
          basket
        });
      })
    );
  }

  @Action(SetHandoffMode)
  setPickUp({ getState, patchState }: StateContext<BasketStateModel>, action: SetHandoffMode) {
    const basketId = getState().basket.id;
    return this.basketService.setDeliveryMode(action.handoffMode, basketId).pipe(
      tap(
        (res: Basket) => {
          patchState({ basket: res });
          if (action.handoffMode === 'dispatch') {
            const location = this.store.selectSnapshot(state => state.restaurant.location);
            this.store.dispatch(new SetDispatchAddress(location, basketId));
          }
        },
        error => {
          this.toast.warning(error.error.message);
        }
      )
    );
  }

  @Action(SetDispatchAddress)
  setDispatchAddress({ patchState }: StateContext<BasketStateModel>, { addressInfo, basketId }: SetDispatchAddress) {
    const body = {
      streetaddress: addressInfo.streetAddress,
      city: addressInfo.city,
      zipcode: addressInfo.zip,
      specialinstructions: addressInfo.specialInstructions
    };
    return this.basketService.setDeliveryAddress(body, basketId).pipe(
      tap((basket: Basket) => {
        patchState({
          basket
        });
      })
    );
  }

  @Action(AddProductToBasket)
  addProductToBasket(
    { patchState, getState }: StateContext<BasketStateModel>,
    { product, metadata }: AddProductToBasket
  ) {
    const state = getState();
    let limiters = new Limiters(state.capacity._items);
    const quantity = product.quantity;
    if (metadata) {
      // tslint:disable-next-line: radix
      const categoryId = parseInt(metadata[metadata.map((el: any) => el.key).indexOf('category')].value);
      // tslint:disable-next-line: radix
      const categoryMax =
        this.mode.getStoredMode() === 'kms'
          ? 9999
          : parseInt(metadata[metadata.map((el: any) => el.key).indexOf('category_max')].value);
      // Attempt an add to the limiter associated with this products category
      // If return value is true, then it was added successfully, and we can process the addition
      // If false, we need to return something to signal a failure
      let limiter: Limiter;
      if (limiters.hasCategory(categoryId)) {
        const limiterState = limiters.items[limiters.find(categoryId)];
        limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
      } else {
        limiter = new Limiter(categoryId, categoryMax);
        limiters = limiters.add(limiter);
        patchState({
          capacity: limiters
        });
      }
      const canAdd = limiter.canAdd(quantity);

      if (canAdd) {
        // TODO: add
        // process adding to basket
        return this.basketService.addProductToBasket(product, state.basket.id, categoryId).pipe(
          tap(
            res => {
              this.store.dispatch(new AddToCategoryLimit(categoryId, product.productid, quantity));
              const basket = res;
              patchState({ basket });
              this.store.dispatch(new GetTimeSlots(false));
              this.analytics.logAddToCart([basket.products[basket.products.length - 1]]);
              // this.toast.success('Product added to bag!');
            }
            // error => {
            //   if (error.error && error.error.message) {
            //     this.toast.warning(error.error.message);
            //   } else {
            //     this.toast.warning('Oops, something went wrong.');
            //   }
            // }
          )
        );
      } else {
        // Signal that the product failed to add because limit reached
      }
    } else {
      // Just add
      return this.basketService.addProductToBasket(product, state.basket.id, null).pipe(
        tap(
          res => {
            const basket = res;
            patchState({ basket });
            this.analytics.logAddToCart([basket.products[basket.products.length - 1]]);
            // this.toast.success('Product added to bag!');
          }
          // error => {
          //   if (error.error && error.error.message) {
          //     this.toast.warning(error.error.message);
          //   } else {
          //     this.toast.warning('Oops, something went wrong.');
          //   }
          // }
        )
      );
    }
  }

  @Action(AddSavedProductToBasket)
  addSavedProductToBasket(ctx: StateContext<BasketStateModel>, action: AddSavedProductToBasket) {
    // return this.basketService.addSingleProductByChainIDToBasket(ctx.getState().basket.id, action.product.product.chainproductid, action.product.product.quantity, action.product.product.choices, action.product.product.specialinstructions, action.product.product.recipient)
    const state = ctx.getState();
    let limiters = new Limiters(state.capacity._items);
    const quantity = action.product.product.quantity;
    if (action.metadata) {
      // tslint:disable-next-line: radix
      const categoryId = parseInt(action.metadata[action.metadata.map((el: any) => el.key).indexOf('category')].value);
      // tslint:disable-next-line: radix
      const categoryMax =
        this.mode.getStoredMode() === 'kms'
          ? 9999
          : parseInt(action.metadata[action.metadata.map((el: any) => el.key).indexOf('category_max')].value);
      // Attempt an add to the limiter associated with this products category
      // If return value is true, then it was added successfully, and we can process the addition
      // If false, we need to return something to signal a failure
      let limiter: Limiter;
      if (limiters.hasCategory(categoryId)) {
        const limiterState = limiters.items[limiters.find(categoryId)];
        limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
      } else {
        limiter = new Limiter(categoryId, categoryMax);
        limiters = limiters.add(limiter);
        ctx.patchState({
          capacity: limiters
        });
      }
      const canAdd = limiter.canAdd(quantity);

      if (canAdd) {
        // TODO: add
        // process adding to basket
        return this.basketService
          .addSingleProductByChainIDToBasket(
            ctx.getState().basket.id,
            action.product.product.chainproductid,
            action.product.product.quantity,
            action.product.product.choices,
            action.product.product.specialinstructions,
            action.product.product.recipient
          )
          .pipe(
            tap(
              res => {
                this.store.dispatch(new AddToCategoryLimit(categoryId, action.productMenuID, quantity));
                const basket = res;
                ctx.patchState({ basket });
                this.store.dispatch(new GetTimeSlots(false));
                this.analytics.logAddToCart([basket.products[basket.products.length - 1]]);
                // this.toast.success('Product added to bag!');
              }
              // error => {
              //   if (error.error && error.error.message) {
              //     this.toast.warning(error.error.message);
              //   } else {
              //     this.toast.warning('Oops, something went wrong.');
              //   }
              // }
            )
          );
      } else {
        // Signal that the product failed to add because limit reached
      }
    } else {
      // Just add
      return this.basketService
        .addSingleProductByChainIDToBasket(
          ctx.getState().basket.id,
          action.product.product.chainproductid,
          action.product.product.quantity,
          action.product.product.choices,
          action.product.product.specialinstructions,
          action.product.product.recipient
        )
        .pipe(
          tap(
            res => {
              const basket = res;
              ctx.patchState({ basket });
              this.analytics.logAddToCart([basket.products[basket.products.length - 1]]);
              // this.toast.success('Product added to bag!');
            }
            // error => {
            //   if (error.error && error.error.message) {
            //     this.toast.warning(error.error.message);
            //   } else {
            //     this.toast.warning('Oops, something went wrong.');
            //   }
            // }
          )
        );
    }
  }

  @Action(SetTipAmount)
  SetTipAmount({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();

    return this.basketService.addTip(payload.payload, state.basket.id).pipe(
      tap(res => {
        const basket: Basket = res;
        patchState({ basket });
        this.store.dispatch(new ValidateBasket());
      })
    );
  }

  @Action(AddToCategoryLimit)
  addToCategoryLimit(
    { patchState, getState }: StateContext<BasketStateModel>,
    { categoryId, productId, quant }: AddToCategoryLimit
  ) {
    const quantity = quant ? quant : 1;
    const state = getState();
    const limiters = new Limiters(state.capacity._items);
    const limiter = limiters.items[limiters.find(categoryId)];
    let limitProducts: any[] = [];
    if (limiter && limiter.products) {
      limitProducts = limiter.products;
    }
    let newProducts = [...limitProducts];
    for (let i = 0; i < quantity; i++) {
      newProducts = [...newProducts, productId];
    }
    const newLimiter = new Limiter(limiter.categoryId, limiter.max, newProducts);

    // Patch state with updated quantity
    // Remove category entry and push newLimiter
    const newLimiters = limiters.remove(categoryId).add(newLimiter);
    patchState({
      capacity: newLimiters
    });
  }

  @Action(AddReorderToLimit)
  addReorderToLimit({ patchState, getState }: StateContext<BasketStateModel>, { reorderMetaData }: AddReorderToLimit) {
    reorderMetaData.forEach((cat: any) => {
      cat.products.forEach((product: any) => {
        const state = getState();
        let limiters = new Limiters(state.capacity._items);
        let limiter: Limiter;
        if (limiters.hasCategory(cat.categoryId)) {
          const limiterState = limiters.items[limiters.find(cat.categoryId)];
          limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
        } else {
          limiter = new Limiter(cat.categoryId, cat.max);
          limiters = limiters.add(limiter);
          patchState({
            capacity: limiters
          });
        }
        const canAdd = limiter.canAdd(1);
        if (canAdd) {
          this.store.dispatch(new AddToCategoryLimit(cat.categoryId, product, 1));
          this.store.dispatch(new GetTimeSlots(false));
        }
      });
    });
  }

  @Action(RemoveFromCategoryLimit)
  removeFromCategoryLimit(
    { patchState, getState }: StateContext<BasketStateModel>,
    { categoryId, productId, quant }: RemoveFromCategoryLimit
  ) {
    const quantity = quant ? quant : 1;
    const state = getState();
    const limiters = new Limiters(state.capacity._items);
    const limiter = limiters.items[limiters.find(categoryId)];
    const newProducts = [...limiter.products];
    for (let i = 0; i < quantity; i++) {
      // Remove productId
      newProducts.splice(
        newProducts.findIndex(p => p === productId),
        1
      );
    }
    const newLimiter = new Limiter(limiter.categoryId, limiter.max, newProducts);

    // Patch state with updated quantity
    // Remove category entry and push newLimiter
    const newLimiters = limiters.remove(categoryId).add(newLimiter);
    patchState({
      capacity: newLimiters
    });
  }

  @Action(RemoveProductFromBasket)
  removeProductFromBasket({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();
    const basketProd = state.basket.products.find((p: BasketProduct) => p.id == payload.payload); // Need double equal because there is a type mismatch

    const productId = basketProd.productId;
    const quantity = basketProd.quantity;

    const limiters = new Limiters(state.capacity._items);
    let limiter: Limiter;
    limiters.items.forEach(l => {
      if (l.products.includes(productId)) {
        limiter = l;
      }
    });

    return this.basketService.removeProductFromBasket(payload.payload, state.basket.id).pipe(
      tap(
        res => {
          // If limiter exists
          if (limiter) {
            this.store.dispatch(new RemoveFromCategoryLimit(limiter.categoryId, productId, quantity));
          }
          const basket = res;
          patchState({ basket });
          this.store.dispatch(new GetTimeSlots(false));
          this.store.dispatch(new ValidateBasket());
          this.analytics.logRemoveFromCart(basketProd);
        }
        // error => {
        //   this.toast.warning(error.error.message);
        // }
      )
    );
  }

  @Action(UpdateSingleProductInBasket)
  updateSingleProductInBasket({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();
    // If the quantity has changed...
    const productId = payload.body.productid;
    let quantDifference = 0;

    if (payload.isIncrementing) {
      quantDifference = state.incrementor;
    }

    let canUpdate = true;
    let updateLimiter = false;
    const limiters = new Limiters(state.capacity._items as Limiter[]);
    const body = payload.body;

    // Get category id associated with this product
    const categoryId = limiters.findCategoryOf(productId);

    // If categoryid > 0, set updateLimiter to true & get Limiter, else not tracked by limiter
    updateLimiter = categoryId > 0;

    if (updateLimiter) {
      const limiter = limiters.of(categoryId);
      // If quant increasing, canUpdate = canAdd
      if (quantDifference > 0) {
        canUpdate = limiter.canAdd(quantDifference);
      }

      // If quant decreasing, canUpdate = canRemove
      if (quantDifference < 0) {
        canUpdate = limiter.canRemove(-quantDifference);
      }
    }

    if (canUpdate) {
      if (updateLimiter) {
        if (quantDifference > 0) {
          limiters.addProductToCategory(productId, categoryId, quantDifference);
        }
        if (quantDifference < 0) {
          limiters.removeProductFromCategory(productId, categoryId, -quantDifference);
        }
      }
      body.customdata = categoryId.toString();

      return this.basketService.updateProductInBasket(payload.basketProductId, state.basket.id, body).pipe(
        tap(
          res => {
            const basket = res;
            patchState({ basket, capacity: limiters });
            this.store.dispatch(new GetTimeSlots(false));
          }
          // error => {
          //   this.toast.warning(error.error.message);
          // }
        )
      );
    } else {
      return of(state.basket).pipe(tap(() => this.toast.warning('Capacity reached for this product type')));
    }
  }

  @Action(SetTimeWanted)
  setTimeWanted({ patchState, getState }: StateContext<BasketStateModel>, { dateTime, isManualFire }: SetTimeWanted) {
    const state = getState();
    const momentTime = dateTime.moment;
    let body;
    if (typeof momentTime === 'string' || momentTime instanceof String) {
      const newDateTime = new DateTime(moment(momentTime));
      // const offsetTime = moment().utcOffset() / 60;
      // const ye = '0' + momentTime.substring(0, 4);
      // const mon = momentTime.substring(5, 7);
      // const today = momentTime.substring(8, 10);
      // const hr = ((parseInt(momentTime.substring(11, 13)) + offsetTime) < 0 ? (parseInt(momentTime.substring(11, 13)) + offsetTime) + 24 : (parseInt(momentTime.substring(11, 13)) + offsetTime)).toString();
      // const min = momentTime.substring(14, 16);
      body = {
        ismanualfire: isManualFire || false,
        ...newDateTime.oloTimeWanted
      };
    } else {
      body = {
        ismanualfire: isManualFire || false,
        ...dateTime.oloTimeWanted
      };
    }

    return this.basketService.setTimeWanted(state.basket.id, body).pipe(
      tap(
        basket => {
          if (isManualFire) {
            const formatManualFire = moment(momentTime).format('YYYY-MM-DD HH:mm:ss');
            this.store.dispatch(new AddManualFireTime(formatManualFire));
          }
          this.store.dispatch(new RemoveOrderPlaceholder());
          patchState({ basket });
        }
        // error => {
        //   this.toast.warning(error.error.message);
        // }
      )
    );
  }

  @Action(SetTimeWantedToAsap)
  setTimeWantedToAsap({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();
    return this.basketService.setTimeWantedToAsap(state.basket.id).pipe(
      tap(
        basket => {
          patchState({ basket });
        }
        // error => {
        //   this.toast.warning(error.error.message);
        // }
      )
    );
  }

  @Action(ApplyCoupon)
  applyCoupon({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();
    return this.basketService.applyCoupon(payload.payload, state.basket.id).pipe(
      tap(res => {
        const basket = res;
        patchState({ basket });
      })
    );
  }

  @Action(RemoveCoupon)
  removeCoupon(ctx: StateContext<BasketStateModel>, action: RemoveCoupon) {
    return this.basketService.removeCoupon(ctx.getState().basket.id).pipe(
      map(res => {
        ctx.dispatch(new ValidateBasket());
        return ctx.patchState({
          basket: res
        });
      })
    );
  }

  @Action(ApplyGiftCard)
  applyGiftCard({ patchState, getState }: StateContext<BasketState>, payload: any) {
    patchState({
      applyGiftCard: payload.payload
    });
  }

  @Action(SetIncrementor)
  setIncrememtor({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    patchState({ incrementor: payload.payload });
  }

  @Action(AddManualFireTime)
  addManualFireTime({ patchState, getState }: StateContext<BasketStateModel>, payload: any) {
    patchState({ manualFireTime: payload.payload });
  }

  @Action(GetBillingSchemes)
  getBillingSchemes({ getState, patchState }: StateContext<BasketStateModel>, payload: any) {
    const basketId = getState().basket.id;
    return this.basketService.getBillingSchemes(basketId).pipe(
      tap(billingSchemes => {
        patchState({ billingSchemes });
      })
    );
  }

  @Action(SetBillingInfo)
  setBillingInfo({ getState, patchState }: StateContext<BasketStateModel>, { billingInfo }: SetBillingInfo) {
    patchState({
      billingInfo
    });
  }

  @Action(CheckBalance)
  checkBalance({ getState, patchState }: StateContext<BasketStateModel>, payload: any) {
    const basket = getState();
    const giftCardId = basket.billingSchemes.find((sche: any) => sche.type === 'giftcard').id;
    return this.basketService.checkGiftBalance(giftCardId, basket.basket.id, payload.payload).pipe(
      tap((res: any) => {
        if (res.balance > 0) {
          const giftBalance = { ...res, cardnumber: payload.payload.cardnumber, pin: payload.payload.pin };
          if (basket.giftCard) {
            patchState({ giftCard: [...basket.giftCard, giftBalance] });
          } else {
            patchState({ giftCard: [giftBalance] });
          }
        }
      })
    );
  }

  @Action(RemoveGiftCard)
  removeGiftCard({ getState, patchState }: StateContext<BasketStateModel>, index: any) {
    const basket = getState();
    if (index.payload === 0 && basket.giftCard.length === 1) {
      patchState({ giftCard: null });
    } else if (index.payload === 0 && basket.giftCard.length === 2) {
      patchState({ giftCard: [basket.giftCard[1]] });
    } else if (index.payload === 1) {
      patchState({ giftCard: [basket.giftCard[0]] });
    }
  }

  @Action(SaveExtraCreditCard)
  saveExtraCreditCard({ getState, patchState }: StateContext<BasketStateModel>, card: any) {
    patchState({ extraCard: card.payload });
  }

  @Action(SetOrderPlaceholder)
  setOrderPlaceholder({ patchState, getState }: StateContext<BasketStateModel>) {
    const state = getState();
    const restaurantId = this.store.selectSnapshot(RestaurantState).restaurant.id;
    let enzoQuant = 0;
    const restaurant = this.store.selectSnapshot(RestaurantState);
    state.basket.products.forEach((p: BasketProduct) => {
      p.choices.forEach((c: BasketChoice) => {
        if (c.name === restaurant.settings.enzo_modifier_name) {
          enzoQuant = enzoQuant + 1 * p.quantity;
        }
      });
    });
    return this.capacityService
      .createOrUpdateOrderMetaDataFromBuckets(
        restaurantId,
        state.basket.id,
        state.buckets,
        true,
        enzoQuant,
        state.basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order'
      )
      .pipe(
        tap((order: any) => {
          const time = order[0].data.created_on;
          const ids: string[] = [];
          order.forEach((o: any) => {
            ids.push(o.data.id.toString());
          });
          const placeholder: CapacityPlaceholder = {
            time,
            directusId: ids
          };
          patchState({ capacityPlaceholder: placeholder });
        })
      );
  }

  @Action(RemoveOrderPlaceholder)
  RemoveOrderPlaceholder({ patchState, getState }: StateContext<BasketStateModel>) {
    /*
    const state = getState();
    if (state.capacityPlaceholder && state.capacityPlaceholder.directusId.length) {
      return combineLatest(state.capacityPlaceholder.directusId.map(id => {
        console.log('id', id);

          return this.capacityService.removeOldOrder(id);
        }
      )).pipe(tap(() => {
        patchState({ capacityPlaceholder: null });
      }))
    } else {
      patchState({ capacityPlaceholder: null });
    }
    */
    patchState({ capacityPlaceholder: null });
  }

  @Action(ValidateBasket)
  validateBasket({ patchState, getState }: StateContext<BasketStateModel>, payload: ValidateBasket) {
    const state = getState();
    return this.basketService.validateBasket(state.basket.id, payload.checkUpsell).pipe(
      switchMap(basketValidation => {
        if (payload.isCheckout) {
          return this.store.dispatch(new GetBasket()).pipe(map(() => patchState({ basketValidation })));
        } else {
          this.store.dispatch(new GetBasket());
          return of(patchState({ basketValidation }));
        }
      })
    );
  }

  @Action(SubmitBasket)
  submitBasket({ patchState, getState, setState, dispatch }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();
    const body: BillingInfo = { ...state.billingInfo };
    let authtoken: string = null;
    const user = this.store.selectSnapshot(UserState);
    if (!!user && !!user.info && !!user.info.authtoken) {
      authtoken = user.info.authtoken;
      body.usertype = 'user';
    }
    const restaurantId = this.store.selectSnapshot(RestaurantState).restaurant.id;
    const capacitySettings = this.store.selectSnapshot(state => state.restaurant).capacity_settings;
    const buckets = state.buckets;
    const currentBucketIds = state?.capacityPlaceholder?.directusId || null;
    const checkBuzzCapacity: boolean =
      getState().basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order';
    return this.capacityService
      .checkLastSecond(
        capacitySettings,
        restaurantId,
        buckets,
        currentBucketIds,
        state?.basket?.products,
        checkBuzzCapacity
      )
      .pipe(
        concatMap(res => {
          if (res.canFit) {
            return this.submitAndSendData(
              body,
              authtoken,
              restaurantId,
              buckets,
              user,
              {
                patchState,
                getState,
                setState,
                dispatch
              },
              payload?.payload?.tableNumber,
              '',
              null,
              null,
              null,
              null,
              '',
              null,
              null
            );
          } else {
            return throwError(
              new Error(
                res.enzoError
                  ? 'Sorry, Gluten-Free is sold out. To proceed with your order please remove Gluten-Free from your basket.'
                  : 'The order time selected is no longer available, please choose a different time'
              )
            ).pipe(
              tap(() => {
                this.store.dispatch(new GetTimeSlots(false)).subscribe(() => {
                  const timeSlots = getState().timeSlots;
                  this.store.dispatch(new SetBuckets(timeSlots[0].value.buckets));
                });
              })
            );
          }
        })
      );
  }

  @Action(SubmitTrancloudBasket)
  submitTrancloudBasket({ patchState, getState, setState, dispatch }: StateContext<BasketStateModel>, payload: any) {
    const state = getState();
    const body: BillingInfo = { ...state.billingInfo };
    let authtoken: string = null;
    const user = this.store.selectSnapshot(UserState);
    if (!!user && !!user.info && !!user.info.authtoken) {
      authtoken = user.info.authtoken;
      body.usertype = 'user';
    }
    const restaurantId = this.store.selectSnapshot(RestaurantState).restaurant.id;
    const capacitySettings = this.store.selectSnapshot(state => state.restaurant).capacity_settings;
    const buckets = state.buckets;
    const currentBucketIds = state.capacityPlaceholder?.directusId || null;
    const checkBuzzCapacity: boolean =
      getState().basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order';
    return dispatch(new GetBasket()).pipe(
      switchMap(() => {
        return this.capacityService
          .checkLastSecond(
            capacitySettings,
            restaurantId,
            buckets,
            currentBucketIds,
            getState().basket?.products,
            checkBuzzCapacity
          )
          .pipe(
            concatMap(res => {
              if (res.canFit) {
                return this.trancloudCardSwipe(
                  body,
                  authtoken,
                  restaurantId,
                  buckets,
                  user,
                  payload?.payload?.tableNumber,
                  {
                    patchState,
                    getState,
                    setState,
                    dispatch
                  }
                );
              } else {
                return throwError(
                  new Error(
                    res.enzoError
                      ? 'Sorry, Gluten-Free is sold out. To proceed with your order please remove Gluten-Free from your basket.'
                      : 'The order time selected is no longer available, please choose a different time'
                  )
                ).pipe(
                  tap(() => {
                    this.store.dispatch(new GetTimeSlots(false)).subscribe(() => {
                      const timeSlots = getState().timeSlots;
                      this.store.dispatch(new SetBuckets(timeSlots[0].value.buckets));
                    });
                  })
                );
              }
            })
          );
      })
    );
  }

  @Action(SubmitBasketMultiPay)
  submitBasketMultiPay(
    { patchState, getState, dispatch }: StateContext<BasketStateModel>,
    payload: SubmitBasketMultiPay
  ) {
    const state = getState();
    const capacitySettings = this.store.selectSnapshot(state => state.restaurant).capacity_settings;
    const buckets = state.buckets;
    const basket = this.store.selectSnapshot(BasketState).billingInfo;
    const user = this.store.selectSnapshot(UserState);
    const restaurantId = this.store.selectSnapshot(RestaurantState).restaurant.id;
    let authtoken: string = null;
    let userType = basket.usertype;
    let firstName = basket.firstname;
    let lastName = basket.lastname;
    let email = basket.emailaddress;
    let contactNumber = basket.contactnumber;

    if (!!user && !!user.info && !!user.info.authtoken) {
      authtoken = user.info.authtoken;
      userType = 'user';
      firstName = user.info.firstname;
      lastName = user.info.lastname;
      email = user.info.emailaddress;
      contactNumber = user.info.contactnumber;
    }
    const body = {
      usertype: userType,
      firstname: firstName,
      lastname: lastName,
      emailaddress: email,
      contactnumber: contactNumber,
      billingaccounts: payload.payload
    };
    const directusIds = state.capacityPlaceholder?.directusId || null;
    const checkBuzzCapacity: boolean =
      getState().basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order';
    return this.capacityService
      .checkLastSecond(capacitySettings, restaurantId, buckets, directusIds, state?.basket?.products, checkBuzzCapacity)
      .pipe(
        concatMap(res => {
          if (res.canFit) {
            return this.basketService.submitOrderMultiPay(state.basket.id, body, authtoken).pipe(
              switchMap((previousBasket: Order) => {
                // Order placed - send meta data to Directus
                Preferences.remove({ key: 'punchBasket' });
                let enzoQuant = 0;
                const restaurant = this.store.selectSnapshot(RestaurantState);
                state.basket.products.forEach((p: BasketProduct) => {
                  p.choices.forEach((c: BasketChoice) => {
                    if (c.name === restaurant.settings.enzo_modifier_name) {
                      enzoQuant = enzoQuant + 1 * p.quantity;
                    }
                  });
                });
                return this.capacityService
                  .createOrUpdateOrderMetaDataFromBuckets(
                    restaurantId,
                    previousBasket.oloid,
                    buckets,
                    false,
                    enzoQuant,
                    state.basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order',
                    directusIds
                  )
                  .pipe(
                    switchMap(() => {
                      return this.expo
                        .postExpoOrder(
                          previousBasket,
                          user,
                          state.manualFireTime,
                          payload?.tableNumber,
                          '',
                          payload.cardNumber,
                          payload.cardType,
                          payload.authCode,
                          payload.refNo,
                          payload.recordNo
                        )
                        .pipe(
                          map(() => {
                            dispatch(new GetRecentOrders());
                            dispatch(new CreateBasket(restaurantId));
                            patchState({ ...state, previousBasket });
                          })
                        );
                    })
                  );
              })
            );
          } else {
            return throwError(
              new Error(
                res.enzoError
                  ? 'Sorry, Gluten-Free is sold out. To proceed with your order please remove Gluten-Free from your basket.'
                  : 'The order time selected is no longer available, please choose a different time'
              )
            ).pipe(
              tap(() => {
                this.store.dispatch(new GetTimeSlots(false)).subscribe(() => {
                  const timeSlots = getState().timeSlots;
                  this.store.dispatch(new SetBuckets(timeSlots[0].value.buckets));
                });
              })
            );
          }
        })
      );
  }

  @Action(CleanGiftCard)
  cleanGiftCard({ getState, patchState }: StateContext<BasketStateModel>) {
    patchState({ giftCard: null });
  }

  @Action(EmptyBasket)
  emptyBasket({ getState, patchState }: StateContext<BasketStateModel>) {
    patchState({ basket: null });
  }

  @Action(EmptyPreviousBasket)
  emptyPreviousBasket({ getState, patchState }: StateContext<BasketStateModel>) {
    patchState({ previousBasket: null });
  }

  @Action(EmptyValidation)
  emptyValidation(ctx: StateContext<BasketStateModel>) {
    return ctx.patchState({
      basketValidation: null
    });
  }

  @Action(EmptyLimits)
  emptyLimits({ getState, patchState }: StateContext<BasketStateModel>) {
    patchState({
      capacity: new Limiters([])
    });
  }

  @Action(TransferBasket)
  transferBasket({ getState, patchState }: StateContext<BasketStateModel>, payload: TransferBasket) {
    const basketId = getState().basket.id;
    return this.basketService.transferBasket(payload.vendorId, basketId).pipe(
      tap(async (basketTransfer: BasketTransfer) => {
        // { basket: Basket, itemsNotTransferred: string[] }
        await Preferences.set({ key: 'punchBasket', value: basketTransfer.basket.id });
        if (basketTransfer.itemsnottransferred && basketTransfer.itemsnottransferred.length > 0) {
          basketTransfer.itemsnottransferred.forEach(productId => {
            this.toast.warning('Item not able to be transferred: #' + productId);
          });
        }
        patchState({ basket: basketTransfer.basket });
      })
    );
  }

  @Action(AddUpsellToBasket)
  addUpsellToBasket({ getState, patchState }: StateContext<BasketStateModel>, payload: AddUpsellToBasket) {
    const basketId = getState().basket.id;
    return this.basketService.addUpsellToBasket(basketId, payload.items).pipe(
      tap((basketRes: Basket) => {
        patchState({ basket: basketRes });
      })
    );
  }

  @Action(SetChange)
  setChange(ctx: StateContext<BasketStateModel>, action: SetChange) {
    return ctx.patchState({
      change: action.change
    });
  }

  @Action(ClearChange)
  clearChange(ctx: StateContext<BasketStateModel>, action: ClearChange) {
    return ctx.patchState({
      change: null
    });
  }

  @Action(ClearExtraCard)
  clearExtraCard(ctx: StateContext<BasketStateModel>, action: ClearChange) {
    return ctx.patchState({
      extraCard: null
    });
  }

  @Action(StoreTrancloudCardData)
  storeTrancloudCardData(ctx: StateContext<BasketStateModel>, action: StoreTrancloudCardData) {
    return ctx.patchState({
      trancloudInfo: {
        cardNumber: action.cardNumber,
        cardType: action.cardType,
        authCode: action.authCode,
        refNo: action.refNo
      }
    });
  }

  @Action(ClearTrancloudCardData)
  clearTrancloudCardData(ctx: StateContext<BasketStateModel>, action: ClearTrancloudCardData) {
    return ctx.patchState({
      trancloudInfo: null
    });
  }

  @Action(SetCustomField)
  setCustomField(ctx: StateContext<BasketStateModel>, action: SetCustomField) {
    return this.basketService.setBasketCustomField(ctx.getState().basket.id, action.customFieldID, action.value).pipe(
      map(basket => {
        return ctx.patchState({
          basket
        });
      })
    );
  }

  @Action(SetReferralToken)
  setReferralToken(ctx: StateContext<BasketStateModel>, action: SetReferralToken) {
    const token = sessionStorage.getItem('rwg_token');
    sessionStorage.removeItem('rwg_token');
    return this.basketService.addReferralToBasket(ctx.getState().basket.id, token, 'rwg_token').pipe(
      map(() => {}),
      catchError(() => of())
    );
  }

  private trancloudCardSwipe(
    body: BillingInfo,
    authtoken: string,
    restaurantId: number,
    buckets: SelectableTime[],
    user: any,
    tableNumber: string,
    { patchState, getState, setState, dispatch }: StateContext<BasketStateModel>
  ) {
    return this.tranCloud.creditCardSale(getState().basket.total).pipe(
      switchMap(res => {
        if (res && res.RStream && res.RStream.CmdStatus === 'Approved') {
          this.basketSubmit.billingmethod = 'prepaid';
          body.billingmethod = 'prepaid';
          this.basketSubmit.prepaidtransactionid = res.RStream.RefNo;
          this.basketSubmit.prepaiddescription = res.RStream.ApplicationLabel || res.RStream.EntryMethod;
          body.prepaidtransactionid = res.RStream.RefNo;
          body.prepaiddescription = res.RStream.ApplicationLabel || res.RStream.EntryMethod;
          this.store.dispatch(
            new StoreTrancloudCardData(
              res.RStream.AcctNo,
              res.RStream.CardType,
              res.RStream.AuthCode,
              res.RStream.RefNo
            )
          );
          return this.submitAndSendData(
            body,
            authtoken,
            restaurantId,
            buckets,
            user,
            {
              patchState,
              getState,
              setState,
              dispatch
            },
            tableNumber,
            res.RStream.RefNo,
            res.RStream.AcctNo,
            res.RStream.CardType,
            res.RStream.AuthCode,
            res.RStream.RefNo,
            res.RStream.RecordNo ? res.RStream.RecordNo : '',
            res.RStream.ProcessData ? res.RStream.ProcessData : '',
            res.RStream.AcqRefData ? res.RStream.AcqRefData : ''
          );
        } else {
          if (res && res.RStream && res.RStream.TextResponse) {
            this.toastr.warning(res.RStream.TextResponse);
            return throwError(() => new Error(res.RStream.TextResponse));
          } else {
            this.toastr.warning('Card Denied');
            return throwError(() => new Error('Card Denied'));
          }
        }
      })
    );
  }

  private submitAndSendData(
    body: any,
    authtoken: string,
    restaurantId: number,
    buckets: SelectableTime[],
    user: any,
    { patchState, getState, setState, dispatch }: StateContext<BasketStateModel>,
    tableNumber: string,
    invoiceNumber: string,
    cardNumber: string,
    cardType: string,
    authCode: string,
    refNo: string,
    recordNo: string,
    processData: string,
    acpRefData: string
  ): Observable<any> {
    const state = getState();
    const directusIds = state?.capacityPlaceholder?.directusId || null;
    return this.basketService.submitOrder(state.basket.id, body, authtoken).pipe(
      catchError(error => {
        if (refNo) {
          return this.tranCloud
            .voidByRecordNo(state.basket.total, refNo, authCode, recordNo, invoiceNumber, processData, acpRefData)
            .pipe(
              switchMap(res => {
                return throwError('Order could not be submitted. Transaction Voided.');
              })
            );
        } else {
          return throwError(error);
        }
      }),
      switchMap((previousBasket: Order) => {
        // Order placed - send meta data to Directus
        Preferences.remove({ key: 'punchBasket' });
        let enzoQuant = 0;
        const restaurant = this.store.selectSnapshot(RestaurantState);
        state.basket.products.forEach((p: BasketProduct) => {
          p.choices.forEach((c: BasketChoice) => {
            if (c.name === restaurant.settings.enzo_modifier_name) {
              enzoQuant = enzoQuant + 1 * p.quantity;
            }
          });
        });
        return this.capacityService
          .createOrUpdateOrderMetaDataFromBuckets(
            restaurantId,
            previousBasket.oloid,
            buckets,
            false,
            enzoQuant,
            state.basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order',
            directusIds
          )
          .pipe(
            retry(2),
            catchError(error => {
              const errorBody = {
                error,
                order: previousBasket,
                user,
                manualFireTime: state.manualFireTime,
                tableNumber,
                invoiceNumber,
                cardNumber,
                cardType,
                authCode,
                refNo,
                recordNo
              };
              return this.errorLogging
                .logError('createOrUpdateOrderMetaDataFromBuckets', errorBody, error.message)
                .pipe(switchMap(() => throwError(error)));
            }),
            switchMap(() => {
              return this.expo
                .postExpoOrder(
                  previousBasket,
                  user,
                  state.manualFireTime,
                  tableNumber,
                  invoiceNumber,
                  cardNumber,
                  cardType,
                  authCode,
                  refNo,
                  recordNo
                )
                .pipe(
                  retry(2),
                  catchError(error => {
                    const errorBody = {
                      error,
                      order: previousBasket,
                      user,
                      manualFireTime: state.manualFireTime,
                      tableNumber,
                      invoiceNumber,
                      cardNumber,
                      cardType,
                      authCode,
                      refNo,
                      recordNo
                    };
                    return this.errorLogging
                      .logError('postExpoOrder', errorBody, error.message)
                      .pipe(switchMap(() => throwError(error)));
                  }),
                  map(() => {
                    // dispatch(new GetRecentOrders());
                    dispatch(new CreateBasket(restaurantId.toString()));
                    dispatch(new EmptyValidation());

                    try {
                      this.analytics.logPurchase(state.basket, previousBasket);
                    } catch (e) {
                      console.error('Analytics failed to log purchase', e);
                    }
                    return patchState({ previousBasket });
                  })
                );
            }),
            catchError(error => {
              const errorBody = {
                error,
                order: previousBasket,
                user,
                manualFireTime: state.manualFireTime,
                tableNumber,
                invoiceNumber,
                cardNumber,
                cardType,
                authCode,
                refNo,
                recordNo
              };
              return this.errorLogging.logError('submitAndSendData', errorBody, error.message).pipe(
                map(() => {
                  dispatch(new CreateBasket(restaurantId.toString()));
                  dispatch(new EmptyValidation());
                  return patchState({ previousBasket });
                })
              );
            })
          );
      })
    );
  }
}
