<div class="container-fluid" *ngIf="user?.recentOrders?.orders as orders">
  <ng-container *ngIf="orders.length > 0; else noAccountInfo">
    <div class="d-flex flex-row flex-wrap favoriteOrdersRow">
      <div class="card favoriteOrderCard" *ngFor="let order of orders; index as i">
        <div class="favoriteOrderName px-3">
          {{ order.deliverymode | handoff }} ORDER {{ order.timeplaced | oloDate | date: 'shortDate' }}
        </div>
        <!--        <div class="favoritecardimagecol">-->
        <!--          <img class="cardimage" [src]="images[i]" />-->
        <!--          <a-->
        <!--            *ngIf="includesBafala"-->
        <!--            class="bafala-icon clickable"-->
        <!--            style="overflow:hidden"-->
        <!--            data-toggle="tooltip"-->
        <!--            data-placement="top"-->
        <!--            title="Contains Mozzarella Di Bufala"-->
        <!--          >-->
        <!--            <div-->
        <!--              style="transform: rotate(45deg); background-color: #333333; position: relative; top: 50px; right: 50px; height: 100px; width: 100px"-->
        <!--            ></div>-->
        <!--            <div>-->
        <!--              <lib-svg-sprite svgID="di-bafala" class="bafala-icon-svg"></lib-svg-sprite>-->
        <!--            </div>-->
        <!--          </a>-->
        <!--        </div>-->
        <div class="card-body" style="float: bottom">
          <div class="d-flex flex-row mb-3">
            <div class="col-12">
              <h5 *ngIf="order.products.length > 0" class="card-title" style="text-transform: uppercase;">
                {{ order.products[0].name }}<br />{{
                  order.products.length > 1 ? '+' + (order.products.length - 1) + ' more' : ''
                }}
              </h5>
              <p class="favoriteOrderPrice">{{ order.total | currency }}</p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-12">
              <div class="d-flex flex-row">
                <div class="col-12">
                  <lib-button
                    (buttonClick)="addRecentOrderItemsToCart(order, maxOrder)"
                    text="Quick Add Items"
                  ></lib-button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-12">
              <div class="d-flex flex-row">
                <div class="col-12 viewMore">
                  <a class="clickable" (click)="pastOrderExpand(pastOrderPopup, orders[i])">View More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noAccountInfo>
  <div class="d-flex flex-row">
    <div class="col-lg-8 col-md-12 noAccountInfo">
      <div class="accountDetailsInnerInfo">
        <div class="accountDetailsHeader">No Past Orders</div>
        <div class="noAccountInfoLine"></div>
      </div>
    </div>

    <div class="col-lg-4 col-md-0"></div>
  </div>
</ng-template>

<ng-template class="pup" #pastOrderPopup let-pastOrderModal>
  <lib-past-order-modal
    [order]="selectedOrder"
    (xClicked)="pastOrderModal.dismiss()"
    (reorder)="addRecentOrderItemsToCart(selectedOrder, maxOrder)"
  >
  </lib-past-order-modal>
</ng-template>

<ng-template class="pup" #maxOrder let-modal>
  <lib-max-pizza-modal (xClicked)="modal.dismiss()"></lib-max-pizza-modal>
</ng-template>
