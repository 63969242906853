import { NgModule } from '@angular/core';
import { ProductService } from './product.service';
import { BasketService } from './basket.service';
import { RestaurantService } from './restaurant.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { MobileService } from '@app/providers/mobile.service';
import { AnalyticsService } from '@app/providers/analytics.service';
import { WiselyService } from '@app/providers/wisely.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    ProductService,
    BasketService,
    RestaurantService,
    EnvironmentService,
    MobileService,
    AnalyticsService,
    WiselyService
  ],
  exports: []
})
export class ServicesModule {}
