import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateModel } from '@app/store/state/app.state';
import { UserStateModel } from '@app/store/state/user.state';
import { ToggleHeader, SetBanner } from '@app/store/actions/app.action';
import { Router } from '@angular/router';
import { ModeService } from '../../../../../app/providers/mode.service';

@Component({
  selector: 'lib-header-hero',
  templateUrl: 'header-hero.component.html',
  styles: []
})
export class HeaderHeroComponent implements OnChanges, OnInit {
  headerHeroScroll: any;
  leftScroll: any;
  rightScroll: any;
  pageHeaderScroll: any;
  headerStuffScroll: any;
  whitecolorScroll: any;
  mobileSmallBackScroll: any;
  headerHero2: any;
  // hasScrolled = false;

  @Input() orderInfoTop: boolean;
  @Input() location: any;
  @Input() menuItem: string;
  @Input() basket: any;
  @Input() hasScrolled = false;
  @Input() mode: string;

  @Select() app$: Observable<AppStateModel>;
  @Select() user$: Observable<UserStateModel>;
  @Select((state: any) => state.user.isMaster) isMaster$: Observable<number>;

  constructor(private _location: Location, private store: Store, private router: Router) {}

  ngOnInit() {
    this.store.dispatch(new ToggleHeader(false));
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.headerHeroScroll = document.getElementById('headerHero1');
    // this.headerHero2 = document.getElementById('headerHero2');
    // this.leftScroll = document.getElementById('left');
    // this.rightScroll = document.getElementById('right');
    // this.pageHeaderScroll = document.getElementById('pageHeader');
    // this.headerStuffScroll = document.getElementById('headerStuff');
    // this.whitecolorScroll = document.getElementById('whitecolor');
    // this.mobileSmallBackScroll = document.getElementById('mobileSmallBack');
    // if (this.hasScrolled === true) {
    //   if (!!this.leftScroll) {
    //     this.leftScroll.classList.add('leftScroll');
    //   }
    //   if (!!this.rightScroll) {
    //     this.rightScroll.classList.add('rightScroll');
    //   }
    //   if (!!this.pageHeaderScroll) {
    //     this.pageHeaderScroll.classList.add('pageHeaderScroll');
    //   }
    //   if (!!this.headerStuffScroll) {
    //     this.headerStuffScroll.classList.add('headerStuffScroll');
    //   }
    //   if (!!this.whitecolorScroll) {
    //     this.whitecolorScroll.classList.add('whitecolorScroll');
    //   }
    //   if (!!this.mobileSmallBackScroll) {
    //     this.mobileSmallBackScroll.classList.add('mobileSmallBackScroll');
    //   }
    //   if (!!this.headerHeroScroll) {
    //     this.headerHeroScroll.classList.add('headerHeroScroll');
    //   }
    // }
    // if (this.hasScrolled === false) {
    //   if (!!this.leftScroll) {
    //     this.leftScroll.classList.remove('leftScroll');
    //   }
    //   if (!!this.rightScroll) {
    //     this.rightScroll.classList.remove('rightScroll');
    //   }
    //   if (!!this.pageHeaderScroll) {
    //     this.pageHeaderScroll.classList.remove('pageHeaderScroll');
    //   }
    //   if (!!this.headerStuffScroll) {
    //     this.headerStuffScroll.classList.remove('headerStuffScroll');
    //   }
    //   if (!!this.whitecolorScroll) {
    //     this.whitecolorScroll.classList.remove('whitecolorScroll');
    //   }
    //   if (!!this.mobileSmallBackScroll) {
    //     this.mobileSmallBackScroll.classList.remove('mobileSmallBackScroll');
    //   }
    //   if (!!this.headerHeroScroll) {
    //     this.headerHeroScroll.classList.remove('headerHeroScroll');
    //   }
    // }
  }

  navBack(bannerText: string) {
    if (bannerText === 'START OVER' && this.mode === 'kms') {
      this.router.navigateByUrl('/kiosk/start-order');
    } else if (bannerText === 'CONFIRM ORDER DETAILS') {
      this.router.navigateByUrl('/menu');
    } else if (bannerText === 'ORDER CONFIRMATION' && this.mode === 'order') {
      this.router.navigateByUrl('/locations');
    } else if (bannerText === 'CONFIRMATION' && this.mode === 'kms') {
      this.router.navigateByUrl('/kiosk/start-order');
    } else {
      this._location.back();
    }
  }

  navigateToFave() {
    const fragment = 'fave';
    this.router.navigate(['/account'], { fragment });
  }

  navigateToRecent() {
    const fragment = 'recent';
    this.router.navigate(['/account'], { fragment });
  }

  navigateToSavedProducts() {
    const fragment = 'saved';
    this.router.navigate(['/account'], { fragment });
  }

  goBackLocList() {
    this.store.dispatch(new SetBanner('Find Your Location', false, false, ''));
  }

  routeToQueue() {
    this.router.navigateByUrl('/expo/queue');
  }
}
