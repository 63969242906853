import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import {
  DirectusCollection,
  DirectusSingleCollection,
  Locations,
  MainSettings,
  ScheduledMessaging
} from '@app/models/directus.collections';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  cmsData: any;
  domainAPI = environment.cmsDomain;
  constructor(private http: HttpClient) {}

  getCMSProducts() {
    return this.http.get(this.domainAPI + 'items/products?fields=*.*&limit=-1');
  }

  @Cacheable({
    maxAge: 30000
  })
  getCMSLocations(): Observable<Locations[]> {
    return this.http
      .get<DirectusCollection<Locations>>(this.domainAPI + 'items/locations?fields=*.*&deep[capacity_days][_limit]=-1')
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  // @Cacheable({
  //   maxAge: 120000
  // })
  getLocation(vendorId: string): Observable<Locations> {
    return this.http
      .get<DirectusCollection<Locations>>(
        this.domainAPI + 'items/locations?fields=*.*&deep[capacity_days][_limit]=-1&filter[olo_id][_eq]=' + vendorId
      )
      .pipe(
        map(res => {
          return res.data[0];
        })
      );
  }

  getScheduledMessages(): Observable<ScheduledMessaging[]> {
    return this.http.get<DirectusCollection<ScheduledMessaging>>(this.domainAPI + 'items/scheduled_messaging').pipe(
      map(res => {
        res.data.forEach(m => {
          m.days_shown = JSON.parse(String(m.days_shown));
        });
        return res.data;
      })
    );
  }

  getOrders() {
    return this.http.get(this.domainAPI + 'items/menu_items');
  }

  getMainSettings(): Observable<MainSettings> {
    return this.http
      .get<DirectusSingleCollection<MainSettings>>(this.domainAPI + 'items/main_settings?fields=*.*')
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  getKioskContent() {
    return;
  }
}
