import { Pipe, PipeTransform } from '@angular/core';
import { BasketChoice } from '@lib/models/olo.basketproduct';
import { orderstatusproduct, orderstatusproductchoice } from '@lib/models/olo.recentorders';

@Pipe({
  name: 'cleanChoices',
  pure: false
})
export class CleanChoicesPipe implements PipeTransform {
  transform(choices: Array<BasketChoice | orderstatusproductchoice>) {
    return choices.filter((choice: BasketChoice | orderstatusproductchoice) => {
      return !(
        choice.name.toLowerCase().includes('included') || choice.name.toLowerCase().includes('additional toppings')
      );
    });
  }
}
