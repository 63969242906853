<div class="formDiv px-3">
  <div class="container formCont">
    <form [formGroup]="createAccountForm" (ngSubmit)="submitForm()">
      <!-- Error Message Display -->
      <div *ngIf="errorMessage" class="d-flex flex-row">
        <div class="col-12">
          <p class="text-danger">{{ errorMessage }}</p>
        </div>
      </div>

      <!-- Email Address Field -->
      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            label="Email Address"
            inputType="email"
            autoComplete="email"
            controlName="emailAddress"
            [parentForm]="createAccountForm"
            [errorMessage]="
              createAccountForm.get('emailAddress').touched && createAccountForm.get('emailAddress').invalid
                ? 'Valid email required.'
                : ''
            "
            inputMode="email"
          >
          </lib-single-text-field>
        </div>
      </div>

      <!-- First and Last Name Fields -->
      <div class="d-flex flex-row">
        <div class="col pe-1">
          <lib-single-text-field
            label="First Name"
            inputType="text"
            controlName="firstName"
            autoComplete="given-name"
            [parentForm]="createAccountForm"
            [errorMessage]="
              createAccountForm.get('firstName').touched && createAccountForm.get('firstName').invalid
                ? 'First name required.'
                : ''
            "
          >
          </lib-single-text-field>
        </div>
        <div class="col ps-1">
          <lib-single-text-field
            label="Last Name"
            inputType="text"
            controlName="lastName"
            autoComplete="family-name"
            [parentForm]="createAccountForm"
            [errorMessage]="
              createAccountForm.get('lastName').touched && createAccountForm.get('lastName').invalid
                ? 'Last name required.'
                : ''
            "
          >
          </lib-single-text-field>
        </div>
      </div>

      <!-- Password and Confirm Password Fields -->
      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            label="Password"
            inputType="password"
            controlName="password"
            autoComplete="new-password"
            [parentForm]="createAccountForm.get('passwords')"
            [errorMessage]="
              createAccountForm.get('passwords').errors?.invalid &&
              createAccountForm.get('passwords').get('password').touched
                ? 'Passwords must match.'
                : ''
            "
          >
          </lib-single-text-field>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            label="Confirm Password"
            inputType="password"
            controlName="confirm"
            autoComplete="new-password"
            [parentForm]="createAccountForm.get('passwords')"
            [errorMessage]="
              createAccountForm.get('passwords').errors?.invalid &&
              createAccountForm.get('passwords').get('confirm').touched
                ? 'Passwords must match.'
                : ''
            "
          >
          </lib-single-text-field>
        </div>
      </div>

      <!-- Phone Number Field -->
      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            label="Phone Number"
            inputType="tel"
            inputMask="(000) 000-0000"
            autoComplete="tel-national"
            controlName="phoneNumber"
            [parentForm]="createAccountForm"
            [errorMessage]="
              createAccountForm.get('phoneNumber').touched && createAccountForm.get('phoneNumber').invalid
                ? 'Valid phone number required.'
                : ''
            "
            inputMode="tel"
          >
          </lib-single-text-field>
        </div>
      </div>

      <!-- Dynamic Contact Lists Checkboxes -->
      <div class="d-flex flex-column">
        <div formArrayName="contactLists">
          <div class="form-check" *ngFor="let contact of contactLists; let i = index">
            <input type="checkbox" [formControlName]="i" class="form-check-input" [id]="'contactList' + i" />
            <label
              class="form-check-label"
              [for]="'contactList' + i"
              style="margin: 0px 20px; vertical-align: text-bottom;"
            >
              {{ contact.description }}
            </label>
          </div>
        </div>
        <small class="text-emphasis">
          <em>
            We will never share your contact information or use it for anything else. You can unsubscribe at any time.
          </em>
        </small>
      </div>

      <!-- Terms and Conditions Checkbox -->
      <div class="d-flex flex-row mt-3">
        <div class="col-12 form-check">
          <input
            type="checkbox"
            formControlName="termsAndConditions"
            class="form-check-input"
            id="termsAndConditions"
          />
          <label
            class="form-check-label"
            for="termsAndConditions"
            style="margin: 0px 20px; vertical-align: text-bottom;"
          >
            {{ termsAndConditionsText }}
          </label>
          <div
            *ngIf="
              createAccountForm.get('termsAndConditions').touched && createAccountForm.get('termsAndConditions').invalid
            "
            class="text-danger"
          >
            You must accept the terms and conditions.
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="d-flex flex-row firstButton mt-4">
        <div class="col-12">
          <lib-button
            class="button"
            large="true"
            [text]="createAccountButtonText"
            buttonType="submit"
            [disabled]="!createAccountForm.valid"
          >
          </lib-button>
        </div>
      </div>
    </form>

    <!-- Sign In and Continue as Guest Buttons -->
    <div class="d-flex flex-row button-margin mt-3">
      <div class="col-12">
        <lib-button class="button" large="true" [text]="signInButtonText" (buttonClick)="clickEvent('sign-in')">
        </lib-button>
      </div>
    </div>

    <div class="bottomGroupLinks mt-2">
      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-svg-sprite svgID="orGroup"></lib-svg-sprite>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-link class="link" [text]="continueAsGuestText" (linkClick)="clickEvent('guest-auth')"></lib-link>
        </div>
      </div>
    </div>
  </div>
</div>
