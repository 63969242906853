import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '@app/store/state.model';
import { Observable } from 'rxjs';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';
import { BasketState } from '@app/store/state/basket.state';
import { Limiter, Limiters } from '@lib/models/Limiter';
import { AddSavedProductToBasket } from '@app/store/actions/basket.action';
import { ProductService } from '@app/providers/product.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetSavedProducts } from '@app/store/actions/user.action';

@Component({
  selector: 'lib-saved-products',
  templateUrl: './saved-products.component.html',
  styleUrls: ['./saved-products.component.scss']
})
export class SavedProductsComponent {
  @Select((state: GlobalStateModel) => state.user.savedProducts) savedProducts$: Observable<
    ResponseRetrieveSavedProductsSavedProduct[]
  >;

  defaultImageUrl = 'assets/images/default.png';
  selectedProdID = 0;
  closeResult = '';

  constructor(private productService: ProductService, private store: Store, private modalService: NgbModal) {
    this.store.dispatch(new SetSavedProducts());
  }

  addSavedProductToCart(savedProduct: ResponseRetrieveSavedProductsSavedProduct, maxOrder?: any) {
    const product = this.productService.getProductFromChainProductID(savedProduct.product.chainproductid);
    this.selectedProdID = product.id;
    // Check if max is reached for product category
    // alert(this.store.selectSnapshot(state => state.basket.capacity.items));
    const state = this.store.selectSnapshot(BasketState);
    const capacity = state.capacity._items;
    const limiters = new Limiters(capacity);
    if (maxOrder && product.metadata && limiters && limiters.hasCategory(parseInt(product.metadata[0].value))) {
      const limiterState = limiters.items[limiters.find(parseInt(product.metadata[0].value))];
      const limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
      if (limiter.isFull() || !limiter.canAdd()) {
        // this.toast.warning('Cannot add more than ' + limiter.max + ' items of this type');
        this.maxOrderLimit(maxOrder);
        this.selectedProdID = 0;
      } else {
        this.store
          .dispatch(new AddSavedProductToBasket(savedProduct, product.id, product.metadata ? product.metadata : []))
          .subscribe(
            data => {
              this.selectedProdID = 0;
            },
            error => {
              this.selectedProdID = 0;
            }
          );
      }
    } else if (product.metadata && limiters) {
      // Quick adding, so only adding quantity = 1
      this.store
        .dispatch(new AddSavedProductToBasket(savedProduct, product.id, product.metadata ? product.metadata : []))
        .subscribe(
          data => {
            this.selectedProdID = 0;
          },
          error => {
            this.selectedProdID = 0;
          }
        );
    } else {
      // No metadata
      this.store
        .dispatch(new AddSavedProductToBasket(savedProduct, product.id, product.metadata ? product.metadata : []))
        .subscribe(
          data => {
            this.selectedProdID = 0;
          },
          error => {
            this.selectedProdID = 0;
          }
        );
    }
  }

  maxOrderLimit(maxOrder: any) {
    this.modalService.open(maxOrder, { ariaLabelledBy: 'maxOrder', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getOrderNameDismissReason(reason)}`;
      }
    );
  }
  private getOrderNameDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      // this.addProductToCart();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // this.addProductToCart();
      return 'by clicking on a backdrop';
    } else {
      // this.addProductToCart();
      return `with: ${reason}`;
    }
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'informationModal', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed`;
      }
    );
  }
}
