// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: true,
  hmr: false,
  version: '1.0.0',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  cmsDomain: 'https://cms.punchpizza.chepri.com/',
  datacapSandboxID: 'ChepriTest',
  datacapSandboxCode: 'nZm6hYh7j2V4ZEnUnmqJzpjF',
  datacapProdID: 'Chepri',
  datacapProdCode: 'Y4Z6FMeVKV3ATf3SR5vW4ncC',
  wsEndpoint: 'capacity.punchpizza.chepri.com',
  mapboxKey: 'pk.eyJ1IjoicHVuY2hwaXp6YSIsImEiOiJja3NldGI1cGcxM3o0MndvMmxkYjFuaXI4In0.WCXxt6-UMH7t22rQ1OKbiA',
  mapboxIOSKey: 'pk.eyJ1IjoicHVuY2hwaXp6YSIsImEiOiJja3JwZXZ2d3EyN2xwMm5xcmRmZnlkN25tIn0.M5du-3ru8lH_MMkNT0m3Fw',
  kioskUserKey: 'cf552390d4a24b609dece75e6648b91f',
  kioskDefaultEmail: 'kioskorder@punchpizza.com',
  kioskDefaultPhone: '6122237200',
  wiselyURL: 'https://capacity.punchpizza.chepri.com/wisely'
};
